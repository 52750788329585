<template>
  <div class="wrap">
    <div class="left-wrap">
      <div class="">問　-{{ question.choice5 }}-</div>
      <p :class="'pi-' + question.id" v-html="question.question1"></p>
      <ol :class="indexStr">
      <li v-for="n in 4" :key="n"  class="d-flex align-items-start">
        <div v-if="question['choice' + String(n)] && questions[index]['choice' + String(n)] != ''">
          <label class="d-flex align-items-start">
            <div class="Ki9LFtxd" v-html="questions[index]['choice' + String(n)]"></div>
          </label>
        </div>
      </li>
    </ol>
    </div>
    <div class="center-border"></div>
    <div class="right-wrap">
      <div class="mb-3">
        <div class="G6zckbvr " :class="{ 'eJ7LMsiQ': questions[index].isBookmark }"></div>問{{ question.no }}
      </div>
      <p :class="'qi-' + question.id" v-html="question.question2"></p>
      <ol class="Ar2jysLK" :class="{ 'Gm8W5aZb': questions[index].isBookmark }"
        v-if="questions[index].currentChoiceArr.length > 0">
        <li v-for="(answer, i) in answers" :key="i">
          <div class="d-flex align-items-start">
            <span>{{ getIndexStr(i) }}</span>
            <ol class="Ar2jysLK mt-0">
              <li class="m-0 mb-2">
                <label class="d-flex align-items-start">
                  <input class="" type="radio" :name="'select' + String(i)" @change="answerExe(i, 1)"
                    :checked="questions[index].currentChoiceArr[i] == '1'">
                  <div class="Ki9LFtxd" style="margin-left: 1em">○</div>
                </label>
              </li>
              <li class="m-0 mb-2">
                <label class="d-flex align-items-start">
                  <input class="" type="radio" :name="'select' + String(i)" @change="answerExe(i, 2)"
                    :checked="questions[index].currentChoiceArr[i] == '2'">
                  <div class="Ki9LFtxd" style="margin-left: 1em">×</div>
                </label>
              </li>
            </ol>

          </div>
        </li>
      </ol>
      <div style="height: 250px;"></div>
    </div>


  </div>
</template>


<script lang="ts">
import { Component, Vue, Emit, Prop } from "vue-property-decorator";
import Question from "../../models/question";
import StorageService from "../../services/storage";
import question from "@/services/question";
import BasicUtil from "@/services/basicUtil";
@Component({
  components: {}
})
export default class Double extends Vue {
  @Prop()
  private question!: Question;
  @Prop()
  private index!: number;
  @Prop()
  private indexs!: number[];
  @Prop()
  public questions!: Question[]

  private indexStr: string = "Ar3jysLB"


  private answers: string[] = []

  private isLock: boolean = false;
  mounted() {
    if (this.questions[this.index].groupId == 0) {
      this.indexStr = "Ar2jysLB"
    } else {
      this.indexStr = "Ar3jysLB"
    }
    this.answers = this.questions[this.index].answer.split(",")
    if (this.questions[this.index].currentChoiceArr.length == 0) {
      const results = []
      for (let i = 0; i < this.answers.length; i++) {
        this.questions[this.index].currentChoiceArr.push("")
        results.push(false)
      }
      this.questions[this.index].results = results
    }

    console.log(this.questions[this.index].currentChoiceArr)
    console.log(this.questions[this.index].results)
  }


  @Emit("answerEmit")
  answerEmit() {
    return
  }

  getIndexStr(i: number) {
    if (this.questions[this.index].groupId == 0) {
      return String(i + 1)
    }
    else {
      let kana = ["ア", "イ", "ウ", "エ", "オ"]
      return "(" + kana[i] + ")"
    }
  }

  public async answerExe(i: number, num: number) {
    
    this.questions[this.index].currentChoiceArr[i] = String(num)
    this.questions[this.index].results[i] = this.answers[i] == this.questions[this.index].currentChoiceArr[i]
    this.$store.commit("setQuestions", this.questions);
    this.answerEmit();
  }


}
</script>

<style scoped>
.wrap {
  margin: -1.5em !important;
  height: calc(100vh - 120px - 90px);
  display: flex;
  align-items: stretch;

}

.right-wrap {
  width: calc(30% - 1px);
  padding: 1.5em;
  padding-left: 1em;
  padding-right: 1em;
  overflow-y: scroll;
}

.center-border {
  width: 2px;
  background-color: #ccc;
  height: 100%;
}

.left-wrap {
  width: calc(70% - 1px);
  padding: 1.5em;
  overflow-y: scroll;
}

.text-r {
  text-align: right;
}
</style>
<style>
.left-wrap img {
  width: 100% !important;
}

.right-wrap img {
  max-width: 100%;
}
</style>