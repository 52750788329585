
export default [
    {
        "id": 1,
        "shikenId": 1,
        "no": 1,
        "answerType": 1,
        "groupId": 0,
        "answer": 3,
        "score": 1,
        "question1": "",
        "question2": "ファイナンシャル・プランナー（以下「FP」という）の顧客に対する行為に関する次の記述のうち、職業倫理や関連法規に照らし、最も適切なものはどれか。(2023.01)",
        "choice1": "顧客から住宅ローンについて相談を受けたFPのAさんは、顧客から預かった給与所得の源泉徴収票のコピーを、顧客に紹介する予定の不動産会社の担当者に顧客の同意を得ないまま渡した。",
        "choice2": "顧客から外貨預金での資金運用について相談を受けたFPのBさんは、円安ドル高がこの先ずっと続くため、円預金の大半をドル預金に移すべきだとアドバイスをした。",
        "choice3": "顧客から老後に受け取ることができる年金について相談を受けたFPのCさんは、社会保険労務士の資格を有していないものの、顧客の「ねんきん定期便」に記載されている年金見込額を用いて、繰り下げた場合の年金受給額を試算した。",
        "choice4": "顧客から所得税の確定申告について相談を受けたFPのDさんは、税理士の資格を有していないものの、顧客の要望に応じて確定申告書の作成を代行した。",
        "choice5": "",
        "categoryName": "ライフ",
        "rontenName": "FPと関連法規",
        "important": ""
    },
    {
        "id": 2,
        "shikenId": 1,
        "no": 2,
        "answerType": 1,
        "groupId": 0,
        "answer": 3,
        "score": 1,
        "question1": "",
        "question2": "ライフプランニングにおける各種係数を用いた必要額の算出に関する次の記述の空欄（ア）、（イ）にあてはまる語句の組み合わせとして、最も適切なものはどれか。なお、算出に当たっては下記＜資料＞の係数を乗算で使用し、手数料や税金等については考慮しないものとする。(2024.01)<br> <br> ・Aさんが60歳から65歳になるまでの5年間、年率2％で複利運用しながら、毎年200万円を受け取る場合、60歳時点の元金として（ア）が必要となる。<br> ・Bさんが45歳から毎年一定額を積み立てながら年率2％で複利運用し、15年後の60歳時に1,000万円を準備する場合、毎年の積立金額は（イ）となる。<br><img src='/img/1_2.jpg'/>",
        "choice1": "（ア）9,057,000円　（イ）578,000円",
        "choice2": "（ア）9,057,000円　（イ）778,000円",
        "choice3": "（ア）9,427,000円　（イ）578,000円",
        "choice4": "（ア）9,427,000円　（イ）778,000円",
        "choice5": "",
        "categoryName": "ライフ",
        "rontenName": "各種係数",
        "important": ""
    },
    {
        "id": 3,
        "shikenId": 1,
        "no": 3,
        "answerType": 1,
        "groupId": 0,
        "answer": 2,
        "score": 1,
        "question1": "",
        "question2": "全国健康保険協会管掌健康保険（協会けんぽ）に関する次の記述のうち、最も適切なものはどれか。(2024.01)",
        "choice1": "一般保険料率は全国一律であるのに対し、介護保険料率は都道府県によって異なる。",
        "choice2": "被保険者の配偶者の父母が被扶養者と認定されるためには、主としてその被保険者により生計を維持され、かつ、その被保険者と同一の世帯に属していなければならない。",
        "choice3": "退職により被保険者資格を喪失した者は、所定の要件を満たせば、最長で3年間、任意継続被保険者となることができる。",
        "choice4": "退職により被保険者資格を喪失した者が任意継続被保険者となるためには、資格喪失日の前日まで継続して1年以上の被保険者期間がなければならない。",
        "choice5": "",
        "categoryName": "ライフ",
        "rontenName": "協会けんぽ",
        "important": ""
    },
    {
        "id": 4,
        "shikenId": 1,
        "no": 4,
        "answerType": 1,
        "groupId": 0,
        "answer": 1,
        "score": 1,
        "question1": "",
        "question2": "雇用保険の育児休業給付および介護休業給付に関する次の記述のうち、最も不適切なものはどれか。なお、記載されたもの以外の要件はすべて満たしているものとする。(2023.05)",
        "choice1": "育児休業給付金は、子が1歳に達した日後の期間について休業することが特に必要と認められる場合、最長で子が1歳2ヵ月に達する日の前日まで支給される。",
        "choice2": "育児休業給付金に係る支給単位期間において支払われた賃金額が、休業開始時賃金日額に支給日数を乗じて得た額の80％相当額以上である場合、当該支給単位期間について育児休業給付金は支給されない。",
        "choice3": "被保険者が、一定の状態にある家族を介護するための休業をした場合、同一の対象家族について、通算3回かつ93日の介護休業を限度として、介護休業給付金が支給される。",
        "choice4": "複数の被保険者が、同一の対象家族について同時に介護休業を取得した場合、それぞれの被保険者に介護休業給付金が支給される。",
        "choice5": "",
        "categoryName": "ライフ",
        "rontenName": "雇用保険",
        "important": ""
    },
    {
        "id": 5,
        "shikenId": 1,
        "no": 5,
        "answerType": 1,
        "groupId": 0,
        "answer": 4,
        "score": 1,
        "question1": "",
        "question2": "公的年金に関する次の記述のうち、最も適切なものはどれか。(2022.05)",
        "choice1": "国民年金の第1号被保険者は、日本国内に住所を有する20歳以上60歳未満の自営業者や学生などのうち、日本国籍を有する者のみが該当する。",
        "choice2": "老齢厚生年金の受給権者が老齢厚生年金の繰下げ支給の申出をする場合、老齢基礎年金の繰下げ支給の申出を同時に行わなければならない。",
        "choice3": "老齢厚生年金の額に加給年金額が加算されるためには、原則として、厚生年金保険の被保険者期間が10年以上であり、かつ、その受給権者によって生計を維持されている一定の要件を満たす配偶者または子がいる必要がある。",
        "choice4": "産前産後休業を取得している厚生年金保険の被保険者の厚生年金保険料は、所定の手続きにより、被保険者負担分と事業主負担分がいずれも免除される。",
        "choice5": "",
        "categoryName": "ライフ",
        "rontenName": "公的年金に関する記述",
        "important": ""
    },
    {
        "id": 6,
        "shikenId": 1,
        "no": 6,
        "answerType": 1,
        "groupId": 0,
        "answer": 4,
        "score": 1,
        "question1": "",
        "question2": "国民年金基金、小規模企業共済および中小企業退職金共済に関する次の記述のうち、最も適切なものはどれか。(2023.05)",
        "choice1": "国民年金基金の加入員が死亡以外の事由で加入員資格を喪失した場合、それまでの加入期間に応じた解約返戻金が支払われる。",
        "choice2": "小規模企業共済の掛金月額は、5,000円から10万円までの範囲内で、500円単位で選択することができる。",
        "choice3": "中小企業退職金共済の掛金は、事業主と被共済者の合意に基づき、事業主と被共済者が折半して負担することができる。",
        "choice4": "中小企業退職金共済の被共済者が退職後3年以内に、中小企業退職金共済の退職金を請求せずに再就職して再び被共済者となった場合、所定の要件を満たせば、前の企業での掛金納付月数を再就職した企業での掛金納付月数と通算することができる。",
        "choice5": "",
        "categoryName": "ライフ",
        "rontenName": "国民年金基金等",
        "important": ""
    },
    {
        "id": 7,
        "shikenId": 1,
        "no": 7,
        "answerType": 1,
        "groupId": 0,
        "answer": 2,
        "score": 1,
        "question1": "",
        "question2": "遺族厚生年金に関する次の記述のうち、最も不適切なものはどれか。(2022.05)",
        "choice1": "厚生年金保険の被保険者が死亡したことにより支給される遺族厚生年金の額は、死亡した者の厚生年金保険の被保険者期間が300月未満の場合、300月とみなして計算する。",
        "choice2": "遺族厚生年金の額（中高齢寡婦加算額および経過的寡婦加算額を除く）は、原則として、死亡した者の厚生年金保険の被保険者記録を基に計算された老齢厚生年金の報酬比例部分の3分の2相当額である。",
        "choice3": "厚生年金保険の被保険者である夫が死亡し、夫の死亡当時に子のいない40歳以上65歳未満の妻が遺族厚生年金の受給権を取得した場合、妻が65歳に達するまでの間、妻に支給される遺族厚生年金には中高齢寡婦加算額が加算される。",
        "choice4": "配偶者が死亡したことにより遺族厚生年金の受給権を取得した65歳以上の受給権者について、その受給権者が受給することができる老齢厚生年金の額が当該遺族厚生年金の額を上回る場合、当該遺族厚生年金の全部が支給停止される。",
        "choice5": "",
        "categoryName": "ライフ",
        "rontenName": "遺族厚生年金",
        "important": ""
    },
    {
        "id": 8,
        "shikenId": 1,
        "no": 8,
        "answerType": 1,
        "groupId": 0,
        "answer": 3,
        "score": 1,
        "question1": "",
        "question2": "リタイアメントプランニング等に関する次の記述のうち、最も不適切なものはどれか。(2023.09)",
        "choice1": "金融機関のリバースモーゲージには、一般に、利用者が死亡し、担保物件の売却代金により借入金を返済した後も債務が残った場合に、利用者の相続人がその返済義務を負う「リコース型」と、返済義務を負わない「ノンリコース型」がある。",
        "choice2": "高齢者の居住の安定確保に関する法律に定める「サービス付き高齢者向け住宅」に入居した者は、「状況把握サービス」や「生活相談サービス」を受けることができる。",
        "choice3": "将来、本人の判断能力が不十分になった場合に備えて、あらかじめ本人が選任した者と締結する任意後見契約は、公正証書によらない場合であっても有効である。",
        "choice4": "確定拠出年金の加入者が、老齢給付金を60歳から受給するためには、通算加入者等期間が10年以上なければならない。",
        "choice5": "",
        "categoryName": "ライフ",
        "rontenName": "リタイアメントプランニング",
        "important": ""
    },
    {
        "id": 9,
        "shikenId": 1,
        "no": 9,
        "answerType": 1,
        "groupId": 0,
        "answer": 3,
        "score": 1,
        "question1": "",
        "question2": "住宅ローンの一般的な特徴に関する次の記述のうち、最も不適切なものはどれか。(2024.05)",
        "choice1": "固定金利選択型の住宅ローンでは、一般に、固定金利期間終了後に適用される金利について、変動金利または固定金利のいずれかを選択することができる。",
        "choice2": "住宅ローンの返済方式では、借入額や返済期間等の他の条件が同一であれば、元金均等返済方式よりも元利均等返済方式の方が総返済額は多い。",
        "choice3": "住宅ローンの一部繰上げ返済では、繰上げ返済額や金利等の他の条件が同一であれば、毎回の返済額を変えずに返済期間を短縮する返済期間短縮型よりも返済期間を変えずに毎回の返済額を少なくする返済額軽減型の方が支払利息の軽減効果は大きい。",
        "choice4": "住宅ローンの借換えに際して、現在借入れをしている金融機関の抵当権を抹消し、借換先の金融機関の抵当権を新たに設定する場合、登録免許税等の諸費用が必要となる。",
        "choice5": "",
        "categoryName": "ライフ",
        "rontenName": "住宅ローンの一般的な特徴",
        "important": ""
    },
    {
        "id": 10,
        "shikenId": 1,
        "no": 10,
        "answerType": 1,
        "groupId": 0,
        "answer": 1,
        "score": 1,
        "question1": "",
        "question2": "中小企業の資金調達の一般的な特徴に関する次の記述のうち、最も不適切なものはどれか。(2023.09)",
        "choice1": "日本政策金融公庫の中小企業事業における融資では、事業用資金だけでなく、投資を目的とする有価証券等の資産の取得資金についても融資対象となる。",
        "choice2": "信用保証協会保証付融資（マル保融資）は、中小企業者が金融機関から融資を受ける際に信用保証協会が保証するものであり、利用するためには、業種に応じて定められた資本金の額（出資の総額）または常時使用する従業員数の要件を満たす必要がある。",
        "choice3": "ABL（動産・債権担保融資）は、企業が保有する売掛債権や在庫・機械設備等の資産を担保として資金を調達する方法である。",
        "choice4": "クラウドファンディングは、インターネット等を介して不特定多数の者に資金の提供を呼びかけて資金を調達する方法であり、「購入型」「寄付型」等に分類される。",
        "choice5": "",
        "categoryName": "ライフ",
        "rontenName": "中小企業の資金調達",
        "important": ""
    },
    {
        "id": 11,
        "shikenId": 1,
        "no": 11,
        "answerType": 1,
        "groupId": 0,
        "answer": 1,
        "score": 1,
        "question1": "",
        "question2": "少額短期保険に関する次の記述のうち、最も適切なものはどれか。(2023.01)",
        "choice1": "少額短期保険は、低発生率保険および経過措置を適用している少額短期保険業者が引き受ける保険契約を除き、被保険者1人につき加入できる保険金額の合計額は1,000万円が上限である。",
        "choice2": "少額短期保険の保険期間は、生命保険、傷害疾病保険および損害保険のいずれも1年が上限である。",
        "choice3": "少額短期保険では、保険期間の満了時に満期返戻金を受け取ることができる。",
        "choice4": "少額短期保険業者が取り扱う保険契約は、保障内容に応じて、生命保険契約者保護機構または損害保険契約者保護機構のいずれかの保護の対象となる。",
        "choice5": "",
        "categoryName": "リスク",
        "rontenName": "少額短期保険",
        "important": ""
    },
    {
        "id": 12,
        "shikenId": 1,
        "no": 12,
        "answerType": 1,
        "groupId": 0,
        "answer": 4,
        "score": 1,
        "question1": "",
        "question2": "生命保険の一般的な商品性に関する次の記述のうち、最も不適切なものはどれか。なお、特約については考慮しないものとする。(2024.01)",
        "choice1": "外貨建て終身保険では、死亡保険金を円貨で受け取る場合、受け取る金額は為替相場によって変動する。",
        "choice2": "変額保険（終身型）では、資産の運用実績に応じて死亡保険金額が変動するが、契約時に定めた保険金額（基本保険金額）は保証される。",
        "choice3": "こども保険（学資保険）では、契約者（＝保険料負担者）が死亡した場合であっても、保険契約は継続し、被保険者である子の成長に合わせて祝金（学資金）等を受け取ることができる。",
        "choice4": "低解約返戻金型終身保険では、他の契約条件が同一であれば、低解約返戻金型ではない終身保険と比較して、保険料払込期間満了後も解約返戻金額が低く設定されている。",
        "choice5": "",
        "categoryName": "リスク",
        "rontenName": "生命保険の一般的な商品性",
        "important": ""
    },
    {
        "id": 13,
        "shikenId": 1,
        "no": 13,
        "answerType": 1,
        "groupId": 0,
        "answer": 1,
        "score": 1,
        "question1": "",
        "question2": "2012年1月1日以後に締結した生命保険契約の保険料に係る生命保険料控除に関する次の記述のうち、最も適切なものはどれか。(2022.09)",
        "choice1": "終身保険の月払保険料について、保険料の支払いがなかったため自動振替貸付により保険料の払込みに充当された金額は、生命保険料控除の対象となる。",
        "choice2": "一般の生命保険料控除、個人年金保険料控除および介護医療保険料控除の控除限度額は、所得税では各3万円である。",
        "choice3": "勤労者財産形成貯蓄積立保険（一般財形）の保険料は、一般の生命保険料控除の対象となる。",
        "choice4": "特定（三大）疾病保障定期保険の保険料は、介護医療保険料控除の対象となる。",
        "choice5": "",
        "categoryName": "リスク",
        "rontenName": "生命保険料控除",
        "important": ""
    },
    {
        "id": 14,
        "shikenId": 1,
        "no": 14,
        "answerType": 1,
        "groupId": 0,
        "answer": 3,
        "score": 1,
        "question1": "",
        "question2": "生命保険の税金に関する次の記述のうち、最も不適切なものはどれか。なお、いずれも契約者（＝保険料負担者）および保険金受取人は個人であるものとする。(2023.09)",
        "choice1": "契約者と被保険者が同一人である養老保険において、被保険者の相続人ではない者が受け取った死亡保険金は、相続税の課税対象となる。",
        "choice2": "契約者と被保険者が同一人である終身保険において、被保険者がリビング・ニーズ特約に基づいて受け取る特約保険金は、非課税となる。",
        "choice3": "契約者と年金受取人が同一人である個人年金保険において、年金受取人が毎年受け取る年金は、所得税における公的年金等控除の対象となる。",
        "choice4": "契約から10年を経過した一時払養老保険を解約して契約者が受け取る解約返戻金は、所得税において総合課税の対象となる。",
        "choice5": "",
        "categoryName": "リスク",
        "rontenName": "生命保険の税金",
        "important": ""
    },
    {
        "id": 15,
        "shikenId": 1,
        "no": 15,
        "answerType": 1,
        "groupId": 0,
        "answer": 2,
        "score": 1,
        "question1": "",
        "question2": "法人を契約者（＝保険料負担者）とする生命保険等に係る保険料の経理処理に関する次の記述のうち、最も不適切なものはどれか。なお、いずれの保険契約も保険料は年払いかつ全期払いで、2023年10月に締結したものとする。(2023.05)",
        "choice1": "被保険者が役員・従業員全員、死亡保険金受取人および満期保険金受取人が法人である養老保険の支払保険料は、その全額を資産に計上する。",
        "choice2": "被保険者が役員、死亡保険金受取人が法人である終身保険の支払保険料は、その全額を損金の額に算入することができる。",
        "choice3": "被保険者が役員、給付金受取人が法人である解約返戻金のない医療保険の支払保険料は、その全額を損金の額に算入することができる。",
        "choice4": "被保険者が役員、死亡保険金受取人が法人で、最高解約返戻率が60％である定期保険（保険期間30年、年払保険料100万円）の支払保険料は、保険期間の前半4割相当期間においては、その40％相当額を資産に計上し、残額を損金の額に算入することができる。",
        "choice5": "",
        "categoryName": "リスク",
        "rontenName": "法人の生命保険の経理処理",
        "important": ""
    },
    {
        "id": 16,
        "shikenId": 1,
        "no": 16,
        "answerType": 1,
        "groupId": 0,
        "answer": 1,
        "score": 1,
        "question1": "",
        "question2": "任意加入の自動車保険の一般的な商品性に関する次の記述のうち、最も不適切なものはどれか。なお、記載のない事項については考慮しないものとする。(2024.05)",
        "choice1": "記名被保険者が被保険自動車を運転中に、ハンドル操作を誤って車庫入れを誘導していた記名被保険者の配偶者に接触してケガをさせた場合、対人賠償保険の補償の対象となる。",
        "choice2": "記名被保険者が被保険自動車を運転中に、ハンドル操作を誤って通行人が連れていたペットの犬に接触してケガをさせ、法律上の損害賠償責任を負った場合、対物賠償保険の補償の対象となる。",
        "choice3": "記名被保険者が被保険自動車を運転中に、ハンドル操作を誤って衣料品販売店の店舗に衝突した場合、店舗の復旧期間中の休業損害は、対物賠償保険の補償の対象となる。",
        "choice4": "豪雨による洪水で被保険自動車が水没して被った損害は、一般条件の車両保険の補償の対象となる。",
        "choice5": "",
        "categoryName": "リスク",
        "rontenName": "自動車保険の一般的な商品性",
        "important": ""
    },
    {
        "id": 17,
        "shikenId": 1,
        "no": 17,
        "answerType": 1,
        "groupId": 0,
        "answer": 2,
        "score": 1,
        "question1": "",
        "question2": "住宅用建物および家財を保険の対象とする火災保険の一般的な商品性に関する次の記述のうち、最も不適切なものはどれか。なお、特約については考慮しないものとする。(2022.09)",
        "choice1": "火災保険の保険料は、対象となる住宅用建物の構造により、M構造、T構造、H構造の3つに区分されて算定される。",
        "choice2": "保険金額が2,000万円（保険価額と同額）の火災保険に加入した後、火災により住宅用建物が損害を被り、損害保険金1,000万円が支払われた場合、保険契約は継続するが、保険期間満了日までの保険金額が1,000万円に減額される。",
        "choice3": "火災保険では、隣家の火災の消火活動により住宅用建物に収容されている家財が損壊した場合、補償の対象となる。",
        "choice4": "火災保険では、雪災により住宅用建物の屋根が損壊して100万円の損害が発生した場合、補償の対象となる。",
        "choice5": "",
        "categoryName": "リスク",
        "rontenName": "火災保険の一般的な商品性",
        "important": ""
    },
    {
        "id": 18,
        "shikenId": 1,
        "no": 18,
        "answerType": 1,
        "groupId": 0,
        "answer": 1,
        "score": 1,
        "question1": "",
        "question2": "傷害保険等の一般的な商品性に関する次の記述のうち、最も不適切なものはどれか。なお、特約については考慮しないものとする。(2022.05)",
        "choice1": "交通事故傷害保険では、交通乗用具に搭乗中の交通事故や交通乗用具の火災事故によるケガは補償の対象となるが、交通乗用具にエレベーターは含まれない。",
        "choice2": "普通傷害保険では、自転車で転倒して負ったケガが原因で罹患した破傷風は補償の対象となる。",
        "choice3": "海外旅行（傷害）保険では、海外旅行中に罹患したウイルス性食中毒は補償の対象となる。",
        "choice4": "所得補償保険では、日本国内外において、保険会社が定める病気やケガによって就業不能となった場合、補償の対象となる。",
        "choice5": "",
        "categoryName": "リスク",
        "rontenName": "傷害保険等の一般的な商品性",
        "important": ""
    },
    {
        "id": 19,
        "shikenId": 1,
        "no": 19,
        "answerType": 1,
        "groupId": 0,
        "answer": 1,
        "score": 1,
        "question1": "",
        "question2": "第三分野の保険の一般的な商品性に関する次の記述のうち、最も適切なものはどれか。なお、記載のない特約については考慮しないものとする。(2024.01)",
        "choice1": "所得補償保険では、勤務先企業の倒産によって失業した場合、保険金は支払われない。",
        "choice2": "更新型の医療保険では、保険期間中に入院給付金を受け取った場合、保険契約を更新することができない。",
        "choice3": "先進医療特約では、契約時点において先進医療に該当していた治療であれば、療養を受けた時点において先進医療に該当しない場合であっても、保険金の支払対象となる。",
        "choice4": "がん保険では、通常、180日間または6ヵ月間の免責期間が設けられている。",
        "choice5": "",
        "categoryName": "リスク",
        "rontenName": "第三分野の保険の一般的な商品性",
        "important": ""
    },
    {
        "id": 20,
        "shikenId": 1,
        "no": 20,
        "answerType": 1,
        "groupId": 0,
        "answer": 2,
        "score": 1,
        "question1": "",
        "question2": "損害保険を利用した事業活動のリスク管理に関する次の記述のうち、最も不適切なものはどれか。(2023.01)",
        "choice1": "製造業を営む事業者が、従業員が就業中や通勤途上でケガをする場合に備えて、すべての従業員を被保険者として普通傷害保険に就業中のみの危険補償特約を付帯して契約した。",
        "choice2": "貸しビル業を営む事業者が、所有するビル内に設置した機械が火災により損害を被る場合に備えて、機械保険を契約した。",
        "choice3": "レストランを営む事業者が、フロア担当従業員が誤って来店客の衣服を汚損する場合に備えて、施設所有（管理）者賠償責任保険を契約した。",
        "choice4": "小型家電製品を製造する事業者が、製造した製品の欠陥が原因で顧客がケガをする場合に備えて、生産物賠償責任保険（PL保険）を契約した。",
        "choice5": "",
        "categoryName": "リスク",
        "rontenName": "損害保険を利用した事業活動のリスク管理",
        "important": ""
    },
    {
        "id": 21,
        "shikenId": 1,
        "no": 21,
        "answerType": 1,
        "groupId": 0,
        "answer": 1,
        "score": 1,
        "question1": "",
        "question2": "為替相場や金利の変動要因等に関する次の記述のうち、最も不適切なものはどれか。(2023.05)",
        "choice1": "日本の物価が米国と比較して相対的に上昇することは、一般に円高米ドル安の要因となる。",
        "choice2": "米国が政策金利を引き上げ、日本と米国との金利差が拡大することは、一般に円安米ドル高の要因となる。",
        "choice3": "日本の対米貿易赤字が拡大することは、一般に円安米ドル高の要因となる。",
        "choice4": "日本銀行が、国債買入オペによって長期国債（利付国債）を買い入れ、金融市場に資金を供給することは、一般に市中金利の低下要因となる。",
        "choice5": "",
        "categoryName": "金融",
        "rontenName": "為替相場や金利の変動要因等",
        "important": ""
    },
    {
        "id": 22,
        "shikenId": 1,
        "no": 22,
        "answerType": 1,
        "groupId": 0,
        "answer": 4,
        "score": 1,
        "question1": "",
        "question2": "銀行等の金融機関で取り扱う預金商品の一般的な商品性等に関する次の記述のうち、最も不適切なものはどれか。なお、本問においては、「民間公益活動を促進するための休眠預金等に係る資金の活用に関する法律」を休眠預金等活用法という。(2022.05)",
        "choice1": "決済用預金は、「無利息」「要求払い」「決済サービスを提供できること」という3つの条件を満たした預金であり、法人も個人も預け入れることができる。",
        "choice2": "オプション取引などのデリバティブを組み込んだ仕組預金には、金融機関の判断によって満期日が繰り上がる商品がある。",
        "choice3": "自動積立定期預金は、各指定日に普通預金口座からの口座振替等により、指定金額を預入することができる定期預金である。",
        "choice4": "2009年1月1日以降、取引がないまま7年が経過した普通預金は、休眠預金等活用法に基づく「休眠預金等」に該当する。",
        "choice5": "",
        "categoryName": "金融",
        "rontenName": "預金商品の一般的な商品性",
        "important": ""
    },
    {
        "id": 23,
        "shikenId": 1,
        "no": 23,
        "answerType": 1,
        "groupId": 0,
        "answer": 4,
        "score": 1,
        "question1": "",
        "question2": "公募株式投資信託の費用に関する次の記述のうち、最も不適切なものはどれか。(2024.01)",
        "choice1": "購入時手数料がかからない投資信託は、一般に、ノーロード型（ノーロードファンド）と呼ばれる。",
        "choice2": "運用管理費用（信託報酬）は投資信託の銘柄ごとに定められており、一般に、インデックス型投資信託よりもアクティブ型投資信託の方が高い傾向がある。",
        "choice3": "会計監査に必要な費用（監査報酬）や組入有価証券に係る売買委託手数料は、信託財産から支出されるため、受益者（投資家）の負担となる。",
        "choice4": "信託財産留保額は、長期に投資信託を保有する投資家との公平性を確保するための費用であり、すべての投資信託に設定されている。",
        "choice5": "",
        "categoryName": "金融",
        "rontenName": "公募株式投資信託の費用",
        "important": ""
    },
    {
        "id": 24,
        "shikenId": 1,
        "no": 24,
        "answerType": 1,
        "groupId": 0,
        "answer": 2,
        "score": 1,
        "question1": "",
        "question2": "固定利付債券の利回り（単利・年率）と価格との関係に関する次の記述の空欄（ア）、（イ）にあてはまる語句の組み合わせとして、最も適切なものはどれか。なお、手数料、経過利子、税金等については考慮しないものとし、計算結果は表示単位の小数点以下第3位を四捨五入するものとする。<br><br>表面利率が1.00％で、償還までの残存期間が5年の固定利付債券を、額面100円当たり102円で購入した投資家が、2年後に、額面100円当たり101円で売却した。この場合の所有期間利回りは（ア）であり、償還期限まで5年間保有した場合の最終利回りよりも（イ）。(2022.09)",
        "choice1": "（ア）0.49％　（イ）高い",
        "choice2": "（ア）0.49％　（イ）低い",
        "choice3": "（ア）0.59％　（イ）高い",
        "choice4": "（ア）0.59％　（イ）低い",
        "choice5": "",
        "categoryName": "金融",
        "rontenName": "固定利付債券の利回りと価格との関係",
        "important": ""
    },
    {
        "id": 25,
        "shikenId": 1,
        "no": 25,
        "answerType": 1,
        "groupId": 0,
        "answer": 3,
        "score": 1,
        "question1": "",
        "question2": "東京証券取引所の市場区分等に関する次の記述のうち、最も適切なものはどれか。(2023.09)",
        "choice1": "スタンダード市場は、「多くの機関投資家の投資対象になりうる規模の時価総額（流動性）を持ち、より高いガバナンス水準を備え、投資者との建設的な対話を中心に据えて持続的な成長と中長期的な企業価値の向上にコミットする企業向けの市場」である。",
        "choice2": "プライム市場は、「高い成長可能性を実現するための事業計画及びその進捗の適時・適切な開示が行われ一定の市場評価が得られる一方、事業実績の観点から相対的にリスクが高い企業向けの市場｣である。",
        "choice3": "スタンダード市場の上場会社がプライム市場へ市場区分の変更をするためには、プライム市場の新規上場基準と同様の基準に基づく審査を受ける必要がある。",
        "choice4": "東証株価指数（TOPIX）は、プライム市場、スタンダード市場およびグロース市場の全銘柄を対象として算出されている。",
        "choice5": "",
        "categoryName": "金融",
        "rontenName": "東京証券取引所の市場区分",
        "important": ""
    },
    {
        "id": 26,
        "shikenId": 1,
        "no": 26,
        "answerType": 1,
        "groupId": 0,
        "answer": 3,
        "score": 1,
        "question1": "",
        "question2": "外貨建ての金融商品の一般的な特徴等に関する次の記述のうち、最も不適切なものはどれか。(2024.05)",
        "choice1": "外貨預金の払戻し時において、預金者が外貨を円貨に換える場合に適用される為替レートは、預入金融機関が提示する対顧客直物電信買相場（TTB）である。",
        "choice2": "外貨預金は、その金額の多寡にかかわらず、預金保険制度による保護の対象とならない。",
        "choice3": "為替予約を締結していない外貨定期預金を満期時に円貨で払い戻した結果生じた為替差益は、一時所得として所得税の課税対象となる。",
        "choice4": "外貨建てMMFは、主に外国の格付けの高い公社債やコマーシャル・ペーパー（CP）などの短期金融商品を投資対象として運用される外貨建ての投資信託である。",
        "choice5": "",
        "categoryName": "金融",
        "rontenName": "外貨建ての金融商品の一般的な特徴",
        "important": ""
    },
    {
        "id": 27,
        "shikenId": 1,
        "no": 27,
        "answerType": 1,
        "groupId": 0,
        "answer": 3,
        "score": 1,
        "question1": "",
        "question2": "先物取引やオプション取引に関する次の記述のうち、最も不適切なものはどれか。(2022.09)",
        "choice1": "現在保有している現物資産が将来値下がりすることに備えるため、先物を売り建てた。",
        "choice2": "将来保有しようとする現物資産が将来値上がりすることに備えるため、先物を買い建てた。",
        "choice3": "現在保有している現物資産が将来値下がりすることに備えるため、プット・オプションを売った。",
        "choice4": "将来保有しようとする現物資産が将来値上がりすることに備えるため、コール・オプションを買った。",
        "choice5": "",
        "categoryName": "金融",
        "rontenName": "先物取引やオプション取引に関する記述",
        "important": ""
    },
    {
        "id": 28,
        "shikenId": 1,
        "no": 28,
        "answerType": 1,
        "groupId": 0,
        "answer": 2,
        "score": 1,
        "question1": "",
        "question2": "下記＜資料＞に基づくファンドAとファンドBの過去5年間の運用パフォーマンスの比較評価に関する次の記述の空欄（ア）、（イ）にあてはまる語句または数値の組み合わせとして、最も適切なものはどれか。(2024.05)<br> <br><img src='/img/1_28.jpg'/><br><br> ファンドの運用パフォーマンスに係る評価指標の一つとして、シャープレシオがある。無リスク金利を全期間にわたり1.0％とし、＜資料＞の数値により、ファンドAのシャープレシオの値を算出すると、（ア）となる。同様にファンドBのシャープレシオの値を算出したうえで、両ファンドの運用パフォーマンスを比較すると、過去5年間は（イ）の方が効率的な運用であったと判断される。",
        "choice1": "（ア）2.0　（イ）ファンドA",
        "choice2": "（ア）2.0　（イ）ファンドB",
        "choice3": "（ア）2.5　（イ）ファンドA",
        "choice4": "（ア）2.5　（イ）ファンドB",
        "choice5": "",
        "categoryName": "金融",
        "rontenName": "運用パフォーマンスの比較評価",
        "important": ""
    },
    {
        "id": 29,
        "shikenId": 1,
        "no": 29,
        "answerType": 1,
        "groupId": 0,
        "answer": 1,
        "score": 1,
        "question1": "",
        "question2": "上場株式等の譲渡および配当等（一定の大口株主等が受けるものを除く）に係る税金に関する次の記述のうち、最も不適切なものはどれか。なお、本問においては、NISA（少額投資非課税制度）により投資収益が非課税となる口座をNISA口座という。(2024.01)",
        "choice1": "上場株式の配当に係る配当所得の金額について、総合課税を選択して所得税の確定申告をした場合、特定口座内で生じた上場株式等に係る譲渡損失の金額と損益通算することができる。",
        "choice2": "NISA口座で保有する上場株式の配当金を非課税扱いにするためには、配当金の受取方法として株式数比例配分方式を選択しなければならない。",
        "choice3": "上場株式等に係る配当所得等の金額と損益通算してもなお控除しきれない上場株式等に係る譲渡損失の金額は、所得税の確定申告をすることにより、翌年以後3年間にわたって繰り越すことができる。",
        "choice4": "NISA口座で取得した上場株式等を売却したことにより生じた損失の金額については、特定口座内で保有する上場株式等の配当等に係る配当所得の金額と損益通算することができない。",
        "choice5": "",
        "categoryName": "金融",
        "rontenName": "上場株式等の譲渡および配当等に係る税金",
        "important": ""
    },
    {
        "id": 30,
        "shikenId": 1,
        "no": 30,
        "answerType": 1,
        "groupId": 0,
        "answer": 4,
        "score": 1,
        "question1": "",
        "question2": "金融商品の取引等に係る各種法令に関する次の記述のうち、最も適切なものはどれか。なお、本問においては、「金融サービスの提供に関する法律」を金融サービス提供法、「犯罪による収益の移転防止に関する法律」を犯罪収益移転防止法という。(2023.01)",
        "choice1": "金融商品取引法では、金融商品取引契約を締結しようとする金融商品取引業者等は、あらかじめ顧客（特定投資家を除く）に契約締結前交付書面を交付しなければならないとされているが、顧客から交付を要しない旨の意思表示があった場合、その交付義務は免除される。",
        "choice2": "金融サービス提供法では、金融サービス仲介業の登録を受けた事業者は、銀行、証券、保険、貸金業の分野のサービスを仲介することができるが、特定の金融機関に所属し、その指導および監督を受けなければならないとされている。",
        "choice3": "消費者契約法では、事業者の不適切な行為によって、消費者が誤認や困惑をし、それによって消費者契約の申込みまたはその承諾の意思表示をした場合、消費者は、当該契約によって生じた損害について賠償を請求することができるとされている。",
        "choice4": "犯罪収益移転防止法では、金融機関等の特定事業者が顧客と特定業務に係る取引を行った場合、特定事業者は、原則として、直ちに当該取引に関する記録を作成し、当該取引の行われた日から7年間保存しなければならないとされている。",
        "choice5": "",
        "categoryName": "金融",
        "rontenName": "金融商品の取引等に係る各種法令",
        "important": ""
    },
    {
        "id": 31,
        "shikenId": 1,
        "no": 31,
        "answerType": 1,
        "groupId": 0,
        "answer": 2,
        "score": 1,
        "question1": "",
        "question2": "所得税の基本的な仕組みに関する次の記述のうち、最も不適切なものはどれか。(2023.09)",
        "choice1": "所得税では、原則として、納税者本人の申告により納付すべき税額が確定し、この確定した税額を納付する申告納税制度が採用されている。",
        "choice2": "所得税の納税義務を負うのは居住者のみであり、非居住者が所得税の納税義務を負うことはない。",
        "choice3": "所得税では、課税対象となる所得を10種類に区分し、それぞれの所得の種類ごとに定められた計算方法により所得の金額を計算する。",
        "choice4": "所得税額の計算において課税総所得金額に乗じる税率は、課税総所得金額が大きくなるにつれて段階的に税率が高くなる超過累進税率が採用されている。",
        "choice5": "",
        "categoryName": "タックス",
        "rontenName": "所得税の基本的な仕組み",
        "important": ""
    },
    {
        "id": 32,
        "shikenId": 1,
        "no": 32,
        "answerType": 1,
        "groupId": 0,
        "answer": 1,
        "score": 1,
        "question1": "",
        "question2": "所得税における各種所得の金額の計算方法に関する次の記述のうち、最も不適切なものはどれか。(2023.05)",
        "choice1": "利子所得の金額は、「利子等の収入金額－元本を取得するために要した負債の利子の額」の算式により計算される。",
        "choice2": "不動産所得の金額は、原則として、「不動産所得に係る総収入金額－必要経費」の算式により計算される。",
        "choice3": "一時所得の金額は、「一時所得に係る総収入金額－その収入を得るために支出した金額－特別控除額」の算式により計算される。",
        "choice4": "退職所得の金額は、特定役員退職手当等および短期退職手当等に係るものを除き、「（退職手当等の収入金額－退職所得控除額）×1／2」の算式により計算される。",
        "choice5": "",
        "categoryName": "タックス",
        "rontenName": "所得税における各種所得の金額の計算方法",
        "important": ""
    },
    {
        "id": 33,
        "shikenId": 1,
        "no": 33,
        "answerType": 1,
        "groupId": 0,
        "answer": 2,
        "score": 1,
        "question1": "",
        "question2": "所得税の損益通算に関する次の記述のうち、最も適切なものはどれか。(2024.01)",
        "choice1": "先物取引に係る雑所得の金額の計算上生じた損失の金額は、不動産所得の金額と損益通算することができる。",
        "choice2": "業務用車両を譲渡したことによる譲渡所得の金額の計算上生じた損失の金額は、事業所得の金額と損益通算することができる。",
        "choice3": "不動産所得の金額の計算上生じた損失の金額のうち、不動産所得を生ずべき土地の取得に要した負債の利子の額に相当する部分の金額は、事業所得の金額と損益通算することができる。",
        "choice4": "生命保険の解約返戻金を受け取ったことによる一時所得の金額の計算上生じた損失の金額は、不動産所得の金額と損益通算することができる。",
        "choice5": "",
        "categoryName": "タックス",
        "rontenName": "所得税の損益通算",
        "important": ""
    },
    {
        "id": 34,
        "shikenId": 1,
        "no": 34,
        "answerType": 1,
        "groupId": 0,
        "answer": 4,
        "score": 1,
        "question1": "",
        "question2": "所得税における医療費控除に関する次の記述のうち、最も不適切なものはどれか。なお、「特定一般用医薬品等購入費を支払った場合の医療費控除の特例」は考慮しないものとする。(2022.05)",
        "choice1": "納税者が自己と生計を一にする配偶者のために支払った医療費の金額は、当該納税者の医療費控除の対象となる。",
        "choice2": "医師等による診療等を受けるために電車、バス等の公共交通機関を利用した場合に支払った通院費で通常必要なものは、医療費控除の対象となる。",
        "choice3": "医療費の補填として受け取った保険金は、その補填の対象となった医療費の金額を限度として、支払った医療費の金額から差し引かれる。",
        "choice4": "納税者が自己の風邪の治療のために支払った医薬品の購入費の金額は、医師の処方がない場合、医療費控除の対象とはならない。",
        "choice5": "",
        "categoryName": "タックス",
        "rontenName": "医療費控除",
        "important": ""
    },
    {
        "id": 35,
        "shikenId": 1,
        "no": 35,
        "answerType": 1,
        "groupId": 0,
        "answer": 4,
        "score": 1,
        "question1": "",
        "question2": "所得税における住宅借入金等特別控除（以下「住宅ローン控除」という）に関する次の記述のうち、最も不適切なものはどれか。なお、2023年10月に住宅ローンを利用して住宅を取得したものとする。(2024.05)",
        "choice1": "住宅ローン控除の適用を受けるためには、原則として、その対象となる家屋を取得等した日から6ヵ月以内に自己の居住の用に供さなければならない。",
        "choice2": "住宅ローン控除の対象となる家屋は、床面積の2分の1以上に相当する部分がもっぱら自己の居住の用に供されるものに限られる。",
        "choice3": "住宅ローン控除の対象となる借入金は、契約による償還期間が10年以上のものに限られる。",
        "choice4": "住宅ローン控除は、納税者が給与所得者である場合、住宅を取得して居住の用に供した年分から年末調整により適用を受けることができる。",
        "choice5": "",
        "categoryName": "タックス",
        "rontenName": "住宅借入金等特別控除",
        "important": ""
    },
    {
        "id": 36,
        "shikenId": 1,
        "no": 36,
        "answerType": 1,
        "groupId": 0,
        "answer": 2,
        "score": 1,
        "question1": "",
        "question2": "法人税の仕組みに関する次の記述のうち、最も適切なものはどれか。(2022.09)",
        "choice1": "法人税の納税地は、原則として、その法人の代表者の住所または居所の所在地である。",
        "choice2": "法人税の各事業年度の所得の金額は、その事業年度の益金の額からその事業年度の損金の額を控除した金額である。",
        "choice3": "期末資本金の額等が1億円以下の一定の中小法人に対する法人税の税率は、所得金額のうち年1,000万円以下の部分について軽減税率が適用される。",
        "choice4": "法人税の確定申告書は、原則として、各事業年度終了の日の翌日から1ヵ月以内に、納税地の所轄税務署長に提出しなければならない。",
        "choice5": "",
        "categoryName": "タックス",
        "rontenName": "法人税の仕組み",
        "important": ""
    },
    {
        "id": 37,
        "shikenId": 1,
        "no": 37,
        "answerType": 1,
        "groupId": 0,
        "answer": 1,
        "score": 1,
        "question1": "",
        "question2": "法人税の損金に関する次の記述のうち、最も不適切なものはどれか。(2022.05)",
        "choice1": "法人が法人税および法人住民税を納付した場合、その全額を損金の額に算入することができる。",
        "choice2": "法人が会議に関連して、茶菓、弁当その他これらに類する飲食物を供与するために通常要する費用を支出した場合、その全額を損金の額に算入することができる。",
        "choice3": "法人が役員に対して定期同額給与を支給した場合、不相当に高額な部分の金額など一定のものを除き、その全額を損金の額に算入することができる。",
        "choice4": "法人が減価償却費として損金経理した金額のうち、償却限度額に達するまでの金額は、その全額を損金の額に算入することができる。",
        "choice5": "",
        "categoryName": "タックス",
        "rontenName": "法人税の損金",
        "important": ""
    },
    {
        "id": 38,
        "shikenId": 1,
        "no": 38,
        "answerType": 1,
        "groupId": 0,
        "answer": 4,
        "score": 1,
        "question1": "",
        "question2": "消費税に関する次の記述のうち、最も不適切なものはどれか。(2023.01)",
        "choice1": "基準期間における課税売上高が1,000万円を超える法人は、消費税の免税事業者となることができない。",
        "choice2": "特定期間における給与等支払額の合計額および課税売上高がいずれも1,000万円を超える法人は、消費税の免税事業者となることができない。",
        "choice3": "基準期間における課税売上高が5,000万円を超える課税事業者は、簡易課税制度の適用を受けることができない。",
        "choice4": "消費税の免税事業者が「消費税課税事業者選択届出書」を提出して消費税の課税事業者となったときは、事業を廃止した場合を除き、原則として3年間は消費税の免税事業者に戻ることができない。",
        "choice5": "",
        "categoryName": "タックス",
        "rontenName": "消費税",
        "important": ""
    },
    {
        "id": 39,
        "shikenId": 1,
        "no": 39,
        "answerType": 1,
        "groupId": 0,
        "answer": 3,
        "score": 1,
        "question1": "",
        "question2": "会社と役員間の取引に係る所得税・法人税に関する次の記述のうち、最も不適切なものはどれか。(2023.09)",
        "choice1": "会社が役員に対して無利息で金銭の貸付けを行った場合、原則として、通常収受すべき利息に相当する金額が、その会社の所得金額の計算上、益金の額に算入される。",
        "choice2": "会社が役員からの借入金について債務免除を受けた場合、その債務免除を受けた金額が、その会社の所得金額の計算上、益金の額に算入される。",
        "choice3": "役員が所有する土地を適正な時価の2分の1未満の価額で会社に譲渡した場合、その役員は、適正な時価の2分の1に相当する金額により当該土地を譲渡したものとして譲渡所得の計算を行う。",
        "choice4": "役員が会社の所有する社宅に無償で居住している場合、原則として、通常の賃貸料相当額が、その役員の給与所得の収入金額に算入される。",
        "choice5": "",
        "categoryName": "タックス",
        "rontenName": "会社と役員間の取引",
        "important": ""
    },
    {
        "id": 40,
        "shikenId": 1,
        "no": 40,
        "answerType": 1,
        "groupId": 0,
        "answer": 2,
        "score": 1,
        "question1": "",
        "question2": "決算書の見方に関する次の記述のうち、最も不適切なものはどれか。(2023.09)",
        "choice1": "損益計算書の営業利益の額は、売上総利益の額から販売費及び一般管理費の額を差し引いた額である。",
        "choice2": "損益計算書の税引前当期純利益の額は、経常利益の額に営業外損益の額を加算・減算した額である。",
        "choice3": "流動比率（％）は、「流動資産÷流動負債×100」の算式で計算される。",
        "choice4": "自己資本比率（％）は、「自己資本÷総資産×100」の算式で計算される。",
        "choice5": "",
        "categoryName": "タックス",
        "rontenName": "決算書と法人税申告書",
        "important": ""
    },
    {
        "id": 41,
        "shikenId": 1,
        "no": 41,
        "answerType": 1,
        "groupId": 0,
        "answer": 1,
        "score": 1,
        "question1": "",
        "question2": "不動産鑑定評価基準における不動産の鑑定評価に関する次の記述のうち、最も不適切なものはどれか。(2022.09)",
        "choice1": "不動産の価格を求める鑑定評価の基本的な手法は、原価法、取引事例比較法および収益還元法に大別され、鑑定評価に当たっては、対象不動産に係る市場の特性等を考慮し、これらのうち最も適した1つの手法に限定して適用することとされている。",
        "choice2": "最有効使用の原則は、不動産の効用が最高度に発揮される可能性に最も富む使用を前提として把握される価格を標準として不動産の価格が形成されるとする原則である。",
        "choice3": "原価法は、価格時点における対象不動産の再調達原価を求め、この再調達原価について減価修正を行って対象不動産の価格を求める手法である。",
        "choice4": "収益還元法は、対象不動産が賃貸用不動産である場合だけでなく、自用の不動産であっても、賃貸を想定することにより適用されるものであるとされている。",
        "choice5": "",
        "categoryName": "不動産",
        "rontenName": "不動産鑑定評価",
        "important": ""
    },
    {
        "id": 42,
        "shikenId": 1,
        "no": 42,
        "answerType": 1,
        "groupId": 0,
        "answer": 2,
        "score": 1,
        "question1": "",
        "question2": "不動産売買の契約に係る民法の規定に関する次の記述のうち、最も不適切なものはどれか。なお、特約については考慮しないものとする。(2024.05)",
        "choice1": "売買契約の締結後、買主の責めに帰すことのできない事由により、当該契約の目的物の引渡債務の全部が履行不能となった場合、買主は、履行の催告をすることなく、直ちに契約の解除をすることができる。",
        "choice2": "売主が種類または品質に関して契約の内容に適合しない目的物を買主に引き渡した場合において、買主がその不適合を知った時から1年以内にその旨を売主に通知しないときは、売主が引渡しの時にその不適合を知っていたとしても、買主は、その不適合を理由として契約の解除をすることができない。",
        "choice3": "売買の目的物である建物が、その売買契約の締結から当該建物の引渡しまでの間に、地震により全壊した場合、買主は、売主に対して建物代金の支払いを拒むことができる。",
        "choice4": "買主が売主に解約手付を交付した場合、売主は、買主が契約の履行に着手する前であれば、受領した手付の倍額を買主に対して現実に提供することにより、契約の解除をすることができる。",
        "choice5": "",
        "categoryName": "不動産",
        "rontenName": "不動産売買の契約に係る民法の規定",
        "important": ""
    },
    {
        "id": 43,
        "shikenId": 1,
        "no": 43,
        "answerType": 1,
        "groupId": 0,
        "answer": 4,
        "score": 1,
        "question1": "",
        "question2": "借地借家法に関する次の記述のうち、最も適切なものはどれか。なお、本問においては、同法第38条による定期建物賃貸借契約を定期借家契約といい、それ以外の建物賃貸借契約を普通借家契約という。また、記載された特約以外のものについては考慮しないものとする。(2022.09)",
        "choice1": "普通借家契約において存続期間を1年未満に定めた場合、その存続期間は1年とみなされる。",
        "choice2": "期間の定めがある普通借家契約において、賃借人は、正当の事由がなければ、賃貸人に対し、更新しない旨の通知をすることができない。",
        "choice3": "定期借家契約は、もっぱら居住の用に供する建物に限られ、事業の用に供する建物については締結することができない。",
        "choice4": "定期借家契約において、その賃料が、近傍同種の建物の賃料に比較して不相当となっても、賃貸借期間中は増減額させないこととする特約をした場合、その特約は有効である。",
        "choice5": "",
        "categoryName": "不動産",
        "rontenName": "借地借家法（借家）",
        "important": ""
    },
    {
        "id": 44,
        "shikenId": 1,
        "no": 44,
        "answerType": 1,
        "groupId": 0,
        "answer": 3,
        "score": 1,
        "question1": "",
        "question2": "借地借家法に関する次の記述のうち、最も不適切なものはどれか。なお、本問においては、同法第22条の借地権を一般定期借地権といい、第22条から第24条の定期借地権等以外の借地権を普通借地権という。(2023.09)",
        "choice1": "普通借地権の設定契約において、期間の定めがないときは、存続期間は30年とされる。",
        "choice2": "普通借地権の存続期間が満了した時点で借地上に建物が存在しない場合は、借地権者が契約の更新を請求しても、従前の契約と同一の条件で契約が更新されたものとはみなされない。",
        "choice3": "一般定期借地権の設定契約において、存続期間は30年とすることができる。",
        "choice4": "一般定期借地権の設定契約は、公正証書による等書面（電磁的記録による場合を含む）によってしなければならない。",
        "choice5": "",
        "categoryName": "不動産",
        "rontenName": "借地借家法（借地）",
        "important": ""
    },
    {
        "id": 45,
        "shikenId": 1,
        "no": 45,
        "answerType": 1,
        "groupId": 0,
        "answer": 3,
        "score": 1,
        "question1": "",
        "question2": "都市計画法に関する次の記述のうち、最も適切なものはどれか。(2024.01)",
        "choice1": "すべての都市計画区域について、都市計画に市街化区域と市街化調整区域の区域区分を定めなければならない。",
        "choice2": "都市計画区域のうち、市街化調整区域は、おおむね10年以内に優先的かつ計画的に市街化を図るべき区域である。",
        "choice3": "開発許可を受けた開発区域内の土地においては、開発工事完了の公告があるまでの間は、原則として、建築物を建築することができない。",
        "choice4": "市街化調整区域内において、農業を営む者の居住の用に供する建築物の建築を目的として行う開発行為は、開発許可を受ける必要がある。",
        "choice5": "",
        "categoryName": "不動産",
        "rontenName": "都市計画法",
        "important": ""
    },
    {
        "id": 46,
        "shikenId": 1,
        "no": 46,
        "answerType": 1,
        "groupId": 0,
        "answer": 4,
        "score": 1,
        "question1": "",
        "question2": "都市計画区域および準都市計画区域内における建築基準法の規定に関する次の記述のうち、最も不適切なものはどれか。(2022.05)",
        "choice1": "建築物の敷地は、原則として、建築基準法に規定する道路に2ｍ以上接していなければならない。",
        "choice2": "敷地の前面道路の幅員が12ｍ未満である建築物の容積率は、原則として、「都市計画で定められた容積率」と「前面道路の幅員に一定の数値を乗じて得たもの」とのいずれか低い方が上限となる。",
        "choice3": "商業地域、工業地域および工業専用地域においては、地方公共団体の条例で日影規制（日影による中高層の建築物の高さの制限）の対象区域として指定することができない。",
        "choice4": "第二種低層住居専用地域においては、高さが8ｍを超える建築物を建築することはできない。",
        "choice5": "",
        "categoryName": "不動産",
        "rontenName": "建築基準法",
        "important": ""
    },
    {
        "id": 47,
        "shikenId": 1,
        "no": 47,
        "answerType": 1,
        "groupId": 0,
        "answer": 1,
        "score": 1,
        "question1": "",
        "question2": "建物の区分所有等に関する法律に関する次の記述のうち、最も不適切なものはどれか。(2023.01)",
        "choice1": "区分所有者は、敷地利用権が数人で有する所有権その他の権利である場合、規約に別段の定めがない限り、敷地利用権を専有部分と分離して処分することができる。",
        "choice2": "区分所有者は、規約に別段の定めがない限り、集会の議決によって管理者を選任し、または解任することができる。",
        "choice3": "集会においては、区分所有者および議決権の各5分の4以上の多数により建替え決議をすることができる。",
        "choice4": "共用部分に対する区分所有者の共有持分は、規約に別段の定めがない限り、各共有者が有する専有部分の床面積の割合による。",
        "choice5": "",
        "categoryName": "不動産",
        "rontenName": "区分所有法",
        "important": ""
    },
    {
        "id": 48,
        "shikenId": 1,
        "no": 48,
        "answerType": 1,
        "groupId": 0,
        "answer": 2,
        "score": 1,
        "question1": "",
        "question2": "不動産の取得に係る税金に関する次の記述のうち、最も適切なものはどれか。(2023.01)",
        "choice1": "不動産取得税は、相続により不動産を取得した場合は課されるが、贈与により不動産を取得した場合は課されない。",
        "choice2": "一定の要件を満たす戸建て住宅（認定長期優良住宅を除く）を新築した場合、不動産取得税の課税標準の算定に当たっては、1戸につき最高1,200万円を価格から控除することができる。",
        "choice3": "登録免許税は、贈与により不動産を取得した場合の所有権移転登記では課されない。",
        "choice4": "登録免許税は、建物を新築した場合の建物表題登記であっても課される。",
        "choice5": "",
        "categoryName": "不動産",
        "rontenName": "不動産の取得に係る税金",
        "important": ""
    },
    {
        "id": 49,
        "shikenId": 1,
        "no": 49,
        "answerType": 1,
        "groupId": 0,
        "answer": 1,
        "score": 1,
        "question1": "",
        "question2": "個人が土地を譲渡した場合の譲渡所得に関する次の記述のうち、最も不適切なものはどれか。(2024.01)",
        "choice1": "土地の譲渡に係る所得については、その土地を譲渡した日の属する年の1月1日における所有期間が10年以下の場合、短期譲渡所得に区分される。",
        "choice2": "譲渡所得の金額の計算上、譲渡した土地の取得費が不明な場合には、譲渡収入金額の5％相当額を取得費とすることができる。",
        "choice3": "相続（限定承認に係るものを除く）により取得した土地を譲渡した場合、その土地の所有期間を判定する際の取得の時期は、被相続人の取得の時期が引き継がれる。",
        "choice4": "土地を譲渡する際に支出した仲介手数料は、譲渡所得の金額の計算上、譲渡費用に含まれる。",
        "choice5": "",
        "categoryName": "不動産",
        "rontenName": "個人が土地を譲渡した場合の譲渡所得",
        "important": ""
    },
    {
        "id": 50,
        "shikenId": 1,
        "no": 50,
        "answerType": 1,
        "groupId": 0,
        "answer": 4,
        "score": 1,
        "question1": "",
        "question2": "不動産の投資判断の手法等に関する次の記述のうち、最も不適切なものはどれか。(2023.05)",
        "choice1": "レバレッジ効果とは、投資に対する収益率が借入金の金利を上回っている場合に、借入金の利用により自己資金に対する利回りが上昇する効果をいう。",
        "choice2": "DCF法は、連続する複数の期間に発生する純収益および復帰価格を、その発生時期に応じて現在価値に割り引いて、それぞれを合計して対象不動産の収益価格を求める手法である。",
        "choice3": "NPV法（正味現在価値法）による投資判断においては、対象不動産から得られる収益の現在価値の合計額が投資額を上回っている場合、その投資は有利であると判定することができる。",
        "choice4": "IRR法（内部収益率法）による投資判断においては、対象不動産に対する投資家の期待収益率が対象不動産の内部収益率を上回っている場合、その投資は有利であると判定することができる。",
        "choice5": "",
        "categoryName": "不動産",
        "rontenName": "不動産の投資判断の手法",
        "important": ""
    },
    {
        "id": 51,
        "shikenId": 1,
        "no": 51,
        "answerType": 1,
        "groupId": 0,
        "answer": 3,
        "score": 1,
        "question1": "",
        "question2": "民法上の贈与に関する次の記述のうち、最も適切なものはどれか。(2023.01)",
        "choice1": "書面によらない贈与は、その履行の終わった部分についても、各当事者が解除をすることができる。",
        "choice2": "定期贈与とは、贈与者が受贈者に対して定期的に財産を給付することを目的とする贈与をいい、贈与者または受贈者のいずれか一方が生存している限り、その効力を失うことはない。",
        "choice3": "負担付贈与では、受贈者がその負担である義務を履行しない場合において、贈与者が相当の期間を定めてその履行の催告をし、その期間内に履行がない場合、原則として、贈与者は、当該贈与の契約の解除をすることができる。",
        "choice4": "死因贈与では、民法の遺贈に関する規定が準用され、贈与者のみの意思表示により成立し、贈与者の死亡によって効力が生じる。",
        "choice5": "",
        "categoryName": "相続",
        "rontenName": "民法上の贈与",
        "important": ""
    },
    {
        "id": 52,
        "shikenId": 1,
        "no": 52,
        "answerType": 1,
        "groupId": 0,
        "answer": 4,
        "score": 1,
        "question1": "",
        "question2": "贈与税の課税財産に関する次の記述のうち、最も不適切なものはどれか。(2022.05)",
        "choice1": "死因贈与により取得した財産は、贈与税の課税対象とならない。",
        "choice2": "離婚による財産分与として取得した財産は、その価額が婚姻中の夫婦の協力によって得た財産の額等を考慮して、社会通念上相当な範囲内である場合、原則として、贈与税の課税対象とならない。",
        "choice3": "保険契約者（＝保険料負担者）が母、被保険者が父、保険金受取人が子である生命保険契約に基づき、父の死亡により子が受け取った死亡保険金は、子が母から贈与により取得したものとして贈与税の課税対象となる。",
        "choice4": "個人が法人からの贈与により取得した金品は、業務に関して受けるものおよび継続的に受けるものを除き、贈与税の課税対象となる。",
        "choice5": "",
        "categoryName": "相続",
        "rontenName": "贈与税の課税財産",
        "important": ""
    },
    {
        "id": 53,
        "shikenId": 1,
        "no": 53,
        "answerType": 1,
        "groupId": 0,
        "answer": 2,
        "score": 1,
        "question1": "",
        "question2": "民法上の遺言に関する次の記述のうち、最も不適切なものはどれか。なお、各選択肢において、ほかに必要とされる要件等はすべて満たしているものとする。(2024.05)",
        "choice1": "公正証書遺言の作成において、遺言者の配偶者は証人として立ち会うことができない。",
        "choice2": "自筆証書遺言の作成に当たって、自筆証書にこれと一体のものとして添付する財産目録をパソコンで作成する場合、その財産目録への署名および押印は不要である。",
        "choice3": "同一の遺言者による公正証書遺言と自筆証書遺言について、それぞれの内容が異なっている場合、その異なっている部分については、作成日付の新しい遺言の内容が効力を有する。",
        "choice4": "自筆証書遺言書保管制度により法務局（遺言書保管所）に保管されている自筆証書遺言は、遺言者の相続開始後、家庭裁判所の検認が不要である。",
        "choice5": "",
        "categoryName": "相続",
        "rontenName": "民法上の遺言",
        "important": ""
    },
    {
        "id": 54,
        "shikenId": 1,
        "no": 54,
        "answerType": 1,
        "groupId": 0,
        "answer": 3,
        "score": 1,
        "question1": "",
        "question2": "遺産の分割に関する次の記述のうち、最も不適切なものはどれか。(2023.05)",
        "choice1": "共同相続人は、一定の場合を除き、遺産の全部ではなく一部の分割内容のみを定めた遺産分割協議書を作成することができる。",
        "choice2": "換価分割は、共同相続人が相続により取得した財産の全部または一部を金銭に換価し、その換価代金を共同相続人の間で分割する方法である。",
        "choice3": "代償分割は、現物分割を困難とする事由がある場合に、共同相続人が家庭裁判所に申し立て、その審判を受けることにより認められる分割方法である。",
        "choice4": "相続人が代償分割により他の相続人から交付を受けた代償財産は、相続税の課税対象となる。",
        "choice5": "",
        "categoryName": "相続",
        "rontenName": "遺産の分割",
        "important": ""
    },
    {
        "id": 55,
        "shikenId": 1,
        "no": 55,
        "answerType": 1,
        "groupId": 0,
        "answer": 4,
        "score": 1,
        "question1": "",
        "question2": "次の費用等のうち、相続税の課税価格の計算上、相続財産の価額から債務控除することができるものはどれか。なお、相続人は債務控除の適用要件を満たしているものとする。(2022.09)",
        "choice1": "被相続人が生前に購入した墓碑の購入代金で、相続開始時点で未払いのもの",
        "choice2": "遺言執行者に支払った被相続人の相続に係る遺言執行費用",
        "choice3": "被相続人に係る初七日および四十九日の法要に要した費用のうち、社会通念上相当と認められるもの",
        "choice4": "被相続人が所有していた不動産に係る固定資産税のうち、相続開始時点で納税義務は生じているが、納付期限が到来していない未払いのもの",
        "choice5": "",
        "categoryName": "相続",
        "rontenName": "債務控除できるもの",
        "important": ""
    },
    {
        "id": 56,
        "shikenId": 1,
        "no": 56,
        "answerType": 1,
        "groupId": 0,
        "answer": 4,
        "score": 1,
        "question1": "",
        "question2": "相続税の納税に関する次の記述のうち、最も不適切なものはどれか。(2023.01)",
        "choice1": "相続により土地を取得した者がその相続に係る相続税について延納を申請する場合、一定の要件を満たせば、その相続により取得した土地以外の土地を延納の担保として提供することができる。",
        "choice2": "相続税は金銭による一括納付が原則であるが、一括納付や延納による金銭の納付が困難な場合、納税義務者は、その納付を困難とする金額を限度に物納を申請することができる。",
        "choice3": "物納に充てることができる財産の種類には順位があり、不動産と上場株式はいずれも第1順位に分類されている。",
        "choice4": "「小規模宅地等についての相続税の課税価格の計算の特例」の適用を受けた宅地等を物納する場合の収納価額は、特例適用前の価額である。",
        "choice5": "",
        "categoryName": "相続",
        "rontenName": "相続税の納税",
        "important": ""
    },
    {
        "id": 57,
        "shikenId": 1,
        "no": 57,
        "answerType": 1,
        "groupId": 0,
        "answer": 3,
        "score": 1,
        "question1": "",
        "question2": "下記＜親族関係図＞において、Aさんの相続が開始した場合の相続税額の計算における遺産に係る基礎控除額として、最も適切なものはどれか。なお、Cさんは相続の放棄をしている。また、Eさんは、Aさんの普通養子（特別養子縁組以外の縁組による養子）である。(2023.05)<br><img src='/img/1_57.jpg'/>",
        "choice1": "4,200万円",
        "choice2": "4,800万円",
        "choice3": "5,400万円",
        "choice4": "6,000万円",
        "choice5": "",
        "categoryName": "相続",
        "rontenName": "遺産に係る基礎控除額",
        "important": ""
    },
    {
        "id": 58,
        "shikenId": 1,
        "no": 58,
        "answerType": 1,
        "groupId": 0,
        "answer": 2,
        "score": 1,
        "question1": "",
        "question2": "宅地の相続税評価額の算定方法等に関する次の記述のうち、最も適切なものはどれか。(2024.01)",
        "choice1": "宅地の評価方法には、路線価方式と倍率方式があり、どちらの方式を採用するかについては、納税者が任意に選択することができる。",
        "choice2": "倍率方式は、固定資産税評価額に国税局長が一定の地域ごとに定める倍率を乗じて計算した金額によって評価する方式である。",
        "choice3": "正面と側方に路線がある宅地（角地）を路線価方式によって評価する場合、原則として、それぞれの路線価に奥行価格補正率を乗じた価額を比較し、低い方の路線価が正面路線価となる。",
        "choice4": "路線価は、路線に面する標準的な宅地の1坪当たりの価額であり、千円単位で表示される。",
        "choice5": "",
        "categoryName": "相続",
        "rontenName": "宅地の相続税評価額の算定方法",
        "important": ""
    },
    {
        "id": 59,
        "shikenId": 1,
        "no": 59,
        "answerType": 1,
        "groupId": 0,
        "answer": 2,
        "score": 1,
        "question1": "",
        "question2": "Aさんの相続が開始した場合の相続税額の計算における下記＜資料＞の甲宅地の評価に関する次の記述のうち、最も適切なものはどれか。なお、記載のない事項については考慮しないものとする。(2023.09)<br><img src='/img/1_59.jpg'/>",
        "choice1": "長男が相続により甲宅地を取得した場合、貸宅地として評価する。",
        "choice2": "長男が相続により甲宅地を取得した場合、自用地として評価する。",
        "choice3": "妻が相続により甲宅地を取得した場合、貸宅地として評価する。",
        "choice4": "妻が相続により甲宅地を取得した場合、貸家建付地として評価する。",
        "choice5": "",
        "categoryName": "相続",
        "rontenName": "相続財産の評価（不動産）",
        "important": ""
    },
    {
        "id": 60,
        "shikenId": 1,
        "no": 60,
        "answerType": 1,
        "groupId": 0,
        "answer": 4,
        "score": 1,
        "question1": "",
        "question2": "中小企業における経営の承継の円滑化に関する法律による「遺留分に関する民法の特例」（以下「本特例」という）に関する次の記述のうち、最も不適切なものはどれか。(2024.05)",
        "choice1": "会社事業後継者が本特例の適用を受けるためには、遺留分を有する旧代表者の推定相続人および会社事業後継者全員の書面による合意が必要である。",
        "choice2": "本特例の適用を受けることにより、会社事業後継者が旧代表者から贈与により取得した自社株式について、その価額を、遺留分を算定するための財産の価額に算入しないことができる。",
        "choice3": "本特例の適用を受けることにより、会社事業後継者が旧代表者から贈与により取得した自社株式について、遺留分を算定するための財産の価額に算入すべき価額を、本特例の適用に係る合意をした時点の価額とすることができる。",
        "choice4": "本特例の対象となる会社事業後継者は、旧代表者の親族に限られる。",
        "choice5": "",
        "categoryName": "相続",
        "rontenName": "遺留分に関する民法の特例",
        "important": ""
    },
    {
        "id": 61,
        "shikenId": 3,
        "no": 1,
        "answerType": 4,
        "groupId": 0,
        "answer": "766700,1109776,49780,1159556",
        "score": "1,1,1,1",
        "question1": "<img src='/img/1_1.png'/>",
        "question2": "Ａさんが、原則として65歳から受給することができる老齢基礎年金および老齢厚生 年金の年金額（2024年度価額）を計算した次の〈計算の手順〉の空欄①～④に入る 最も適切な数値を解答欄に入力しなさい。なお、計算にあたっては、《設例》の〈Ａさ ん夫妻に関する資料〉および下記の〈資料〉に基づくこと。また、問題の性質上、 明らかにできない部分は「□□□」で示してある。<br><img src='/img/1_2.png'/>",
        "choice1": "円",
        "choice2": "円",
        "choice3": "円",
        "choice4": "円",
        "choice5": "2024.05(改）",
        "categoryName": "ライフ",
        "rontenName": "老齢基礎年金・老齢厚生年金の年金額",
        "important": ""
    },
    {
        "id": 62,
        "shikenId": 3,
        "no": 2,
        "answerType": 5,
        "groupId": 0,
        "answer": "1,2,2",
        "score": "1,1,1",
        "question1": "<img src='/img/1_1.png'/>",
        "question2": "Ｍさんは、Ａさんに対して、公的年金制度の老齢給付について説明した。Ｍさんが 説明した次の記述①～③について、適切なものには○印を、不適切なものには×印を 解答欄に入力しなさい。<br><br>① 「Ａさんが老齢厚生年金の繰上げ支給を請求する場合、老齢基礎年金の繰上げ支給 の請求も同時に行わなければなりません」<br>② 「Ａさんが75歳０カ月で老齢基礎年金および老齢厚生年金の繰下げ支給の申出をす る場合、それぞれの年金額の増額率は70％となります」<br>③ 「Ａさんおよび妻Ｂさんは、1961年４月２日以降生まれであるため、いずれも報酬 比例部分のみの特別支給の老齢厚生年金を受給することはできません」",
        "choice1": "",
        "choice2": "",
        "choice3": "",
        "choice4": "",
        "choice5": "2024.05",
        "categoryName": "ライフ",
        "rontenName": "公的年金制度の老齢給付",
        "important": ""
    },
    {
        "id": 63,
        "shikenId": 3,
        "no": 3,
        "answerType": 6,
        "groupId": 0,
        "answer": "チ,ホ,ロ",
        "score": "1,1,1",
        "question1": "<img src='/img/1_1.png'/>",
        "question2": "Ｍさんは、Ａさんに対して、社会保険の各種取扱いについて説明した。Ｍさんが説 明した以下の文章の空欄①～③に入る最も適切な数値を、下記の〈数値群〉のなかか ら選び、その記号を解答欄に入力しなさい。<br><img src='/img/3_1.png'/>",
        "choice1": "イ|ロ|ハ|ニ|ホ|ヘ|ト|チ|リ|ヌ",
        "choice2": "",
        "choice3": "",
        "choice4": "",
        "choice5": "2024.05",
        "categoryName": "ライフ",
        "rontenName": "社会保険の各種取扱い",
        "important": ""
    },
    {
        "id": 64,
        "shikenId": 3,
        "no": 4,
        "answerType": 5,
        "groupId": 0,
        "answer": "2,1,1",
        "score": "1,1,1",
        "question1": "<img src='/img/3_64.png'/>",
        "question2": "Ｍさんは、Ａさんと長女Ｃさんに対して、株式取引のルール等について説明した。Ｍさんが説明した次の記述①～③について、適切なものには○印を、不適切なものには×印を解答欄に入力しなさい。<br><br>①「国内株式市場における代表的な株価指標である日経平均株価は、東京証券取引所のスタンダード市場に上場している銘柄のうち、代表的な225銘柄を対象とした修正平均型の株価指標です」<br>②「上場株式の注文方法のうち、指値注文では、高い値段の買い注文が低い値段の買い注文に優先して売買が成立し、同じ値段の買い注文については、寄付や引けなどを除き、先に出された注文が後に出された注文に優先して売買が成立します」<br>③「Ｘ社株式の次回の配当を受け取るためには、普通取引の場合、権利確定日の２営業日前である本年６月28日（水）までに買付けを行い、権利確定日に株主として株主名簿に記載される必要があります」",
        "choice1": "",
        "choice2": "",
        "choice3": "",
        "choice4": "",
        "choice5": "2023.05（改）",
        "categoryName": "金融",
        "rontenName": "株式取引のルール等",
        "important": ""
    },
    {
        "id": 65,
        "shikenId": 3,
        "no": 5,
        "answerType": 4,
        "groupId": 0,
        "answer": "10.83,2.23",
        "score": "2,2",
        "question1": "<img src='/img/3_64.png'/>",
        "question2": "《設例》のデータに基づいて算出される次の①、②を求め、解答欄に入力しなさい（計算過程の記載は不要）。〈答〉は表示単位の小数点以下第３位を四捨五入し、小数点以下第２位までを解答すること。<br>①Ｘ社株式のＰＥＲ<br>②Ｙ社株式のＰＢＲ",
        "choice1": "倍",
        "choice2": "倍",
        "choice3": "",
        "choice4": "",
        "choice5": "2023.05",
        "categoryName": "金融",
        "rontenName": "PERとPBRの計算",
        "important": ""
    },
    {
        "id": 66,
        "shikenId": 3,
        "no": 6,
        "answerType": 5,
        "groupId": 0,
        "answer": "1,2,2",
        "score": "1,1,1",
        "question1": "<img src='/img/3_64.png'/>",
        "question2": "Ｍさんは、Ａさんと長女Ｃさんに対して、《設例》のデータに基づいて、株式の投資指標等について説明した。Ｍさんが説明した次の記述①～③について、適切なものには○印を、不適切なものには×印を解答欄に入力しなさい。<br><br>①「一般に、ＲＯＥの数値が高いほうが経営の効率性が高いと判断されます。ＲＯＥは、Ｙ社のほうがⅩ社よりも高くなっています」<br>②「株主への利益還元の大きさに着目した指標として、配当性向があります。配当性向は、Ⅹ社のほうがＹ社よりも高くなっています」<br>③「株式投資において、ＰＥＲやＰＢＲ等が低い銘柄など、企業の業績や財務内容等からみて株価が割安と判断される銘柄に投資する手法は、一般に、グロース投資と呼ばれます」",
        "choice1": "",
        "choice2": "",
        "choice3": "",
        "choice4": "",
        "choice5": "2023.05",
        "categoryName": "金融",
        "rontenName": "株式の投資指標等",
        "important": ""
    },
    {
        "id": 67,
        "shikenId": 3,
        "no": 7,
        "answerType": 6,
        "groupId": 0,
        "answer": "ヘ,ル,イ,ホ",
        "score": "1,1,1,1",
        "question1": "<img src='/img/3_67.png'/>",
        "question2": "所得税における医療費控除に関する以下の文章の空欄①～④に入る最も適切な数値を、下記の〈数値群〉のなかから選び、その記号を解答欄に入力しなさい。<br><img src='/img/3_67_2.png'/>",
        "choice1": "イ|ロ|ハ|ニ|ホ|ヘ|ト|チ|リ|ヌ|ル|ヲ",
        "choice2": "",
        "choice3": "",
        "choice4": "",
        "choice5": "2023.01",
        "categoryName": "タックス",
        "rontenName": "医療費控除に関する数値",
        "important": ""
    },
    {
        "id": 68,
        "shikenId": 3,
        "no": 8,
        "answerType": 5,
        "groupId": 0,
        "answer": "2,2,1",
        "score": "1,1,1",
        "question1": "<img src='/img/3_67.png'/>",
        "question2": "Ａさんの本年分の所得税の課税に関する次の記述①～③について、適切なものには○印を、不適切なものには×印を解答欄に入力しなさい。<br><br>①「Ａさんは、配偶者控除の適用を受けることはできませんが、配偶者特別控除の適用を受けることができます」<br>②「Ａさんが適用を受けることができる扶養控除の控除額は、101万円です」<br>③「Ａさんが医療費控除の適用を受けるためには、所得税の確定申告が必要です。年末調整では医療費控除の適用を受けることができません」",
        "choice1": "",
        "choice2": "",
        "choice3": "",
        "choice4": "",
        "choice5": "2023.01（改）",
        "categoryName": "タックス",
        "rontenName": "所得税の課税",
        "important": ""
    },
    {
        "id": 69,
        "shikenId": 3,
        "no": 9,
        "answerType": 4,
        "groupId": 0,
        "answer": "700,640",
        "score": "1,2",
        "question1": "<img src='/img/3_67.png'/>",
        "question2": "Ａさんの本年分の所得金額について、次の①、②を求め、解答欄に入力しなさい（計算過程の記載は不要）。なお、①の計算上、Ａさんが所得金額調整控除の適用対象者に該当している場合、所得金額調整控除額を控除すること。また、〈答〉は万円単位とすること。<br>①総所得金額に算入される給与所得の金額<br>②総所得金額<br><img src='/img/3_69.png'/>",
        "choice1": "万円",
        "choice2": "万円",
        "choice3": "",
        "choice4": "",
        "choice5": "2023.01（改）",
        "categoryName": "タックス",
        "rontenName": "所得金額の計算",
        "important": ""
    },
    {
        "id": 70,
        "shikenId": 3,
        "no": 10,
        "answerType": 4,
        "groupId": 0,
        "answer": "600,1800",
        "score": "2,2",
        "question1": "<img src='/img/3_70.png'/>",
        "question2": "甲土地上に耐火建築物を建築する場合における次の①、②を求め、解答欄に入力しなさい（計算過程の記載は不要）。<br>①建蔽率の上限となる建築面積<br>②容積率の上限となる延べ面積",
        "choice1": "㎡",
        "choice2": "㎡",
        "choice3": "",
        "choice4": "",
        "choice5": "2024.01",
        "categoryName": "不動産",
        "rontenName": "建築面積と延べ面積の上限",
        "important": ""
    },
    {
        "id": 71,
        "shikenId": 3,
        "no": 11,
        "answerType": 5,
        "groupId": 0,
        "answer": "1,2,1",
        "score": "1,1,1",
        "question1": "<img src='/img/3_70.png'/>",
        "question2": "定期借地権方式による甲土地の有効活用に関する次の記述①～③について、適切なものには○印を、不適切なものには×印を解答欄に記入しなさい。<br><br>①「定期借地権方式は、事業者等に対して甲土地を一定期間賃貸する手法です。仮に、Ａさんが甲土地に事業用定期借地権を設定する場合、その契約は公正証書によってしなければなりません」<br>②「事業用定期借地権は、ドラッグストアやコンビニ等の店舗だけでなく、賃貸マンションや老人ホーム等の居住用の施設を有する建物を建築する場合にも設定することができます」<br>③「甲土地に建物譲渡特約付借地権を設定した場合、その設定後30年以上を経過した日に、Ａさんが甲土地上の建物を借地権者から買い取ったときは、借地契約が終了します。買い取った建物は賃貸することで家賃収入を得ることができますが、建物の維持管理の状態などによっては、十分な収益が見込めない可能性があります」",
        "choice1": "",
        "choice2": "",
        "choice3": "",
        "choice4": "",
        "choice5": "2024.01",
        "categoryName": "不動産",
        "rontenName": "定期借地権方式による土地の有効活用",
        "important": ""
    },
    {
        "id": 72,
        "shikenId": 3,
        "no": 12,
        "answerType": 5,
        "groupId": 0,
        "answer": "2,2,1",
        "score": "1,1,1",
        "question1": "<img src='/img/3_70.png'/>",
        "question2": "定期借地権方式により甲土地を有効活用する場合の課税等に関する次の記述①～③について、適切なものには○印を、不適切なものには×印を解答欄に入力しなさい。<br><br>①「Ａさんが甲土地に定期借地権を設定した場合、甲土地上の建物については借地権者が、甲土地については借地権割合に基づきＡさんと借地権者が、それぞれ固定資産税の納税義務者となります」<br>②「Ａさんが甲土地に事業用定期借地権を設定し、その存続期間中にＡさんの相続が開始した場合、相続税額の計算上、甲土地は貸家建付地として評価されます」<br>③「Ａさんが甲土地に事業用定期借地権を設定した場合、当該借地契約は、借地権者から申出があっても更新することはできませんが、Ａさんと借地権者の合意のもと、借地借家法で定められた事業用定期借地権の存続期間内で存続期間を延長することや、存続期間満了時において再契約することは可能です」",
        "choice1": "",
        "choice2": "",
        "choice3": "",
        "choice4": "",
        "choice5": "2024.01",
        "categoryName": "不動産",
        "rontenName": "土地を有効活用する場合の課税等",
        "important": ""
    },
    {
        "id": 73,
        "shikenId": 3,
        "no": 13,
        "answerType": 6,
        "groupId": 0,
        "answer": "ロ,ホ,ヘ",
        "score": "1,1,1",
        "question1": "<img src='/img/3_73.png'/>",
        "question2": "Ａさんの相続等に関する以下の文章の空欄①～③に入る最も適切な語句または数値を、下記の〈語句群〉のなかから選び、その記号を解答欄に入力しなさい。<br><img src='/img/3_73_2.png'/>",
        "choice1": "イ|ロ|ハ|ニ|ホ|ヘ|ト",
        "choice2": "",
        "choice3": "",
        "choice4": "",
        "choice5": "2022.05（改）",
        "categoryName": "相続",
        "rontenName": "相続等に関する語句または数値",
        "important": ""
    },
    {
        "id": 74,
        "shikenId": 3,
        "no": 14,
        "answerType": 5,
        "groupId": 0,
        "answer": "1,2,2",
        "score": "1,1,1",
        "question1": "<img src='/img/3_73.png'/>",
        "question2": "Ａさんの相続等に関する次の記述①～③について、適切なものには○印を、不適切なものには×印を解答欄に入力しなさい。<br><br>①「孫Ｅさんは、相続税額の２割加算の対象になりますが、孫Ｆさんは、二男Ｄさんの代襲相続人ですので、相続税額の２割加算の対象にはなりません」<br>②「Ｘ社株式の相続税評価額は、原則として、類似業種比準方式と純資産価額方式の併用方式により評価されます。そのうち類似業種比準方式は、類似業種の株価を基に、１株当たりの『配当金額』『取引金額』および『簿価純資産価額』の３つの要素から算出します」<br>③「相続税の総額は、各相続人の実際の取得割合によって計算されますので、分割内容によって異なります」",
        "choice1": "",
        "choice2": "",
        "choice3": "",
        "choice4": "",
        "choice5": "2022.05",
        "categoryName": "相続",
        "rontenName": "相続等に関する記述",
        "important": ""
    },
    {
        "id": 75,
        "shikenId": 3,
        "no": 15,
        "answerType": 4,
        "groupId": 0,
        "answer": "8000,4800,1415,6770",
        "score": "1,1,1,1",
        "question1": "<img src='/img/3_73.png'/>",
        "question2": "各相続人は《設例》の記載のとおり、相続財産を取得した。Ａさんの相続に係る相続税の総額を計算した下記の表の空欄①～④に入る最も適切な数値を求めなさい。なお、問題の性質上、明らかにできない部分は「□□□」で示してある。<br><img src='/img/3_75_2.png'/>",
        "choice1": "万円",
        "choice2": "万円",
        "choice3": "万円",
        "choice4": "万円",
        "choice5": "2022.05",
        "categoryName": "相続",
        "rontenName": "相続税の総額",
        "important": ""
    },
    {
        "id": 76,
        "shikenId": 4,
        "no": 1,
        "answerType": 4,
        "groupId": 0,
        "answer": "768400,1149765,48080,1605945",
        "score": "1,1,1,1",
        "question1": "<img src='/img/4_76.png'/>",
        "question2": "Ａさんが、原則として65歳から受給することができる老齢基礎年金および老齢厚生年金の年金額（2024年度価額）を計算した次の＜計算の手順＞の空欄①～④に入る最も適切な数値を解答欄に入力しなさい。計算にあたっては、《設例》の＜Ａさん夫妻に関する資料＞および下記の＜資料＞に基づくこと。なお、問題の性質上、明らかにできない部分は「□□□」で示してある。<br><img src='/img/4_76_2.png'/>",
        "choice1": "円",
        "choice2": "円",
        "choice3": "円",
        "choice4": "円",
        "choice5": "2023.05",
        "categoryName": "ライフ",
        "rontenName": "年金額の計算",
        "important": ""
    },
    {
        "id": 77,
        "shikenId": 4,
        "no": 2,
        "answerType": 5,
        "groupId": 0,
        "answer": "2,1,2",
        "score": "1,1,1",
        "question1": "<img src='/img/4_76.png'/>",
        "question2": "Ｍさんは、Ａさんに対して、Ａさん夫妻が受給することができる公的年金制度からの老齢給付について説明した。Ｍさんが説明した次の記述①～③について、適切なものには○印を、不適切なものには×印を解答欄に入力しなさい。<br><br>①「Ａさんは特別支給の老齢厚生年金を受給することができませんが、妻Ｂさんは64歳から報酬比例部分のみの特別支給の老齢厚生年金を受給することができます」<br>②「仮に、Ａさんが60歳０カ月で老齢基礎年金および老齢厚生年金の繰上げ支給を請求した場合の減額率は24％となります。繰上げ支給を請求した場合は、一生涯減額された年金額を受け取ることになります」<br>③「Ａさんが希望すれば、66歳以後、老齢基礎年金および老齢厚生年金の繰下げ支給の申出をすることができます。Ａさんの場合、繰下げの上限年齢は70歳です」",
        "choice1": "",
        "choice2": "",
        "choice3": "",
        "choice4": "",
        "choice5": "2023.05",
        "categoryName": "ライフ",
        "rontenName": "公的年金制度からの老齢給付",
        "important": ""
    },
    {
        "id": 78,
        "shikenId": 4,
        "no": 3,
        "answerType": 6,
        "groupId": 0,
        "answer": "ニ,イ,チ",
        "score": "1,1,1",
        "question1": "<img src='/img/4_76.png'/>",
        "question2": "Ｍさんは、Ａさんに対して、雇用保険の高年齢雇用継続基本給付金および在職老齢年金について説明した。Ｍさんが説明した以下の文章の空欄①～③に入る最も適切な語句または数値を、下記の〈語句群〉のなかから選び、その記号を解答欄に入力しなさい。<br><br>Ⅰ　「ＡさんがＸ社の継続雇用制度を利用して、60歳以後も引き続きＸ社に勤務し、かつ、60歳以後の各月（支給対象月）に支払われた賃金額（みなし賃金を含む）が60歳到達時の賃金月額の（　①　）％未満となる場合、Ａさんは、所定の手続により、原則として、高年齢雇用継続基本給付金を受給することができます。高年齢雇用継続基本給付金の額は、支給対象月ごとに、その月に支払われた賃金額の低下率に応じて、一定の方法により算定されますが、賃金額が60歳到達時の賃金月額の61％未満となる場合、原則として、当該金額は賃金額の（　②　）％に相当する額になります」<br>Ⅱ　「Ａさんが、65歳以後も引き続き厚生年金保険の被保険者としてＸ社に勤務し、かつ、65歳から老齢厚生年金を受給する場合、Ａさんの老齢厚生年金の報酬比例部分の額に基づく基本月額と総報酬月額相当額との合計額が（　③　）支給停止調整額、（2024年度価額）以下のときは調整が行われず、老齢厚生年金は全額支給されます」<br><img src='/img/4_78.png'/>",
        "choice1": "イ|ロ|ハ|ニ|ホ|ヘ|ト|チ|リ",
        "choice2": "",
        "choice3": "",
        "choice4": "",
        "choice5": "2023.05（改）",
        "categoryName": "ライフ",
        "rontenName": "高年齢雇用継続基本給付金",
        "important": ""
    },
    {
        "id": 79,
        "shikenId": 4,
        "no": 4,
        "answerType": 5,
        "groupId": 0,
        "answer": "2,2,1",
        "score": "1,1,1",
        "question1": "<img src='/img/4_79.png'/>",
        "question2": "Ｍさんは、Ａさんに対して、公的年金制度からの給付および公的介護保険からの保険給付について説明した。Ｍさんが説明した次の記述①～③について、適切なものには○印を、不適切なものには×印を解答欄に入力しなさい。なお、各記述において、ほかに必要とされる要件等は満たしていることとする。<br><br>①「Ａさんが死亡した場合、妻Ｂさんに対して、遺族厚生年金が支給されます。遺族厚生年金の額は、原則として、Ａさんの厚生年金保険の被保険者記録を基礎として計算した老齢厚生年金の報酬比例部分の額の３分の２相当額になります」<br>②「Ａさんが病気やケガで重度の障害状態となり、その障害の程度が障害等級１級と認定された場合、Ａさんは障害厚生年金を受給することができますが、Ａさんには子どもがいないため、障害基礎年金を受給することはできません」<br>③「Ａさんのような公的介護保険の第２号被保険者は、要介護状態または要支援状態となった原因が特定疾病によって生じたものでなければ、公的介護保険からの保険給付は受けられません。特定疾病の具体例として、末期がん、脳血管疾患、初老期における認知症などが挙げられます」",
        "choice1": "",
        "choice2": "",
        "choice3": "",
        "choice4": "",
        "choice5": "2024.01",
        "categoryName": "保険（個人）",
        "rontenName": "公的年金制度・公的介護保険",
        "important": ""
    },
    {
        "id": 80,
        "shikenId": 4,
        "no": 5,
        "answerType": 5,
        "groupId": 0,
        "answer": "1,1,1,2",
        "score": "1,1,1,1",
        "question1": "<img src='/img/4_79.png'/>",
        "question2": "Ｍさんは、Ａさんに対して、Ａさんが提案を受けた生命保険の内容等について説明した。Ｍさんが説明した次の記述①～④について、適切なものには○印を、不適切なものには×印を解答欄に入力しなさい。<br><br>①「Ａさんが死亡した場合、妻Ｂさんに支払われる死亡保険金額は、600万円となります。Ａさんが死亡した場合の必要保障額を算出し、準備すべき死亡保障の額を確認したうえで、死亡保険金額をご検討ください」<br>②「Ａさんが病気やケガで重度の障害状態となって働けなくなった場合、Ａさんの収入が減るだけでなく、妻Ｂさんの仕事にも影響がでることが想定されます。現在提案を受けている生活介護収入保障特約など、重い障害や介護に備えることができる保障を準備することは検討に値します」<br>③「がん等の重度疾病については、再発のリスクがあり、治療期間も長期にわたるケースがあります。そのため、重度疾病の保障を準備する際には、再発時の保障の有無や、保険金等が支払われる疾病の種類および状態を確認する必要があります」<br>④「Ａさんが厚生労働大臣により定められた先進医療による療養を受けたとき、その先進医療の技術に係る費用と同額を先進医療給付金として受け取ることができます。ただし、先進医療特約の対象は入院を伴った治療のみであり、外来での治療は対象外となります」",
        "choice1": "",
        "choice2": "",
        "choice3": "",
        "choice4": "",
        "choice5": "2024.01",
        "categoryName": "保険（個人）",
        "rontenName": "生命保険の内容等",
        "important": ""
    },
    {
        "id": 81,
        "shikenId": 4,
        "no": 6,
        "answerType": 6,
        "groupId": 0,
        "answer": "ニ,ロ,リ",
        "score": "1,1,1",
        "question1": "<img src='/img/4_79.png'/>",
        "question2": "Ｍさんは、Ａさんに対して、Ａさんが提案を受けた生命保険の課税関係について説明した。Ｍさんが説明した以下の文章の空欄①～③に入る最も適切な語句または数値を、下記の〈語句群〉のなかから選び、その記号を解答欄に入力しなさい。<br><br>Ⅰ　「支払保険料のうち、終身保険特約および定期保険特約に係る保険料は一般の生命保険料控除の対象となります。他方、生活介護収入保障特約、重度疾病保障特約および総合医療特約等に係る保険料は介護医療保険料控除の対象となります。それぞれの適用限度額は、所得税で（　①　）円、住民税で（　②　）円です」<br>Ⅱ　「被保険者であるＡさんが入院給付金などを請求することができない特別な事情がある場合には、指定代理請求人である妻ＢさんがＡさんに代わって請求することができます。妻Ｂさんが指定代理請求人として受け取る入院給付金は、（　③　）となります」<br><img src='/img/4_81.png'/>",
        "choice1": "イ|ロ|ハ|ニ|ホ|ヘ|ト|チ|リ",
        "choice2": "",
        "choice3": "",
        "choice4": "",
        "choice5": "2024.01",
        "categoryName": "保険（個人）",
        "rontenName": "生命保険の課税関係",
        "important": ""
    },
    {
        "id": 82,
        "shikenId": 4,
        "no": 7,
        "answerType": 4,
        "groupId": 0,
        "answer": "1500,1750",
        "score": "1,2",
        "question1": "<img src='/img/4_80.png'/>",
        "question2": "仮に、将来Ｘ社がＡさんに役員退職金5,000万円を支給した場合、Ａさんが受け取る役員退職金について、次の①、②を求め、解答欄に入力しなさい（計算過程の記載は不要）。〈答〉は万円単位とすること。なお、Ａさんの役員在任期間（勤続年数）を29年６カ月とし、これ以外に退職手当等の収入はなく、障害者になったことが退職の直接の原因ではないものとする。<br>①退職所得控除額<br>②退職所得の金額",
        "choice1": "万円",
        "choice2": "万円",
        "choice3": "",
        "choice4": "",
        "choice5": "2024.05",
        "categoryName": "保険（法人）",
        "rontenName": "退職所得の金額",
        "important": ""
    },
    {
        "id": 83,
        "shikenId": 4,
        "no": 8,
        "answerType": 6,
        "groupId": 0,
        "answer": "イ,ニ,チ,ル",
        "score": "1,1,1,1",
        "question1": "<img src='/img/4_80.png'/>",
        "question2": "Ｍさんは、Ａさんに対して、中小企業退職金共済制度（以下、「中退共」という）について説明した。Ｍさんが説明した以下の文章の空欄①～④に入る最も適切な語句を、下記の〈語句群〉のなかから選び、その記号を解答欄に入力しなさい。<br><br>「中退共は、中小企業の事業主が勤労者退職金共済機構と雇用者（従業員）を被共済者とする退職金共済契約を締結して、退職金を社外に積み立てる共済制度です。<br>掛金は、被共済者１人につき、月額5,000円から３万円までの範囲内から選択し、（　①　）負担します。なお、短時間労働者（パートタイマー等）は、月額2,000円から加入することができ、役員は原則として加入することができません。<br>また、中退共に新たに加入する事業主に対して、原則として、掛金月額の（　②　）（被共済者１人ごとに5,000円が上限）を加入後４カ月目から（　③　）、国が助成する制度があります。<br>被共済者が中途（生存）退職したときの退職金は、勤労者退職金共済機構から（　④　）支給されます」<br><img src='/img/4_83.png'/>",
        "choice1": "イ|ロ|ハ|ニ|ホ|ヘ|ト|チ|リ|ヌ|ル",
        "choice2": "",
        "choice3": "",
        "choice4": "",
        "choice5": "2024.05",
        "categoryName": "保険（法人）",
        "rontenName": "中小企業退職金共済制度",
        "important": ""
    },
    {
        "id": 84,
        "shikenId": 4,
        "no": 9,
        "answerType": 5,
        "groupId": 0,
        "answer": "2,1,1",
        "score": "1,1,1",
        "question1": "<img src='/img/4_80.png'/>",
        "question2": "Ｍさんは、Ａさんに対して、〈資料〉の養老保険（福利厚生プラン）について説明した。Ｍさんが説明した次の記述①～③について、適切なものには○印を、不適切なものには×印を解答欄に入力しなさい。<br><br>①「福利厚生プランの保険料は、その全額を福利厚生費として損金の額に算入します」<br>②「福利厚生プランは、原則として、役員・従業員全員を被保険者とする等の普遍的加入でなければなりませんので、制度導入後に入社した従業員について加入漏れがないように注意してください」<br>③「死亡保険金が被保険者の遺族に支払われた場合、Ｘ社は当該保険契約に係る資産計上額を取り崩し、当該金額を雑損失として損金の額に算入します」",
        "choice1": "",
        "choice2": "",
        "choice3": "",
        "choice4": "",
        "choice5": "2024.05",
        "categoryName": "保険（法人）",
        "rontenName": "養老保険（福利厚生プラン）",
        "important": ""
    },
    {
        "id": 85,
        "shikenId": 4,
        "no": 10,
        "answerType": 6,
        "groupId": 0,
        "answer": "ト,ニ,イ,チ",
        "score": "1,1,1,1",
        "question1": "<img src='/img/4_85.png'/>",
        "question2": "所得税における青色申告制度に関する以下の文章の空欄①～④に入る最も適切な語句または数値を、下記の〈語句群〉のなかから選び、その記号を解答欄に入力しなさい。<br><img src='/img/4_85_2.png'/>",
        "choice1": "イ|ロ|ハ|ニ|ホ|ヘ|ト|チ|リ|ヌ",
        "choice2": "",
        "choice3": "",
        "choice4": "",
        "choice5": "2022.09",
        "categoryName": "タックス",
        "rontenName": "所得税における青色申告制度",
        "important": ""
    },
    {
        "id": 86,
        "shikenId": 4,
        "no": 11,
        "answerType": 5,
        "groupId": 0,
        "answer": "1,1,2",
        "score": "1,1,1",
        "question1": "<img src='/img/4_85.png'/>",
        "question2": "Ａさんの本年分の所得税の課税等に関する次の記述①～③について、適切なものには○印を、不適切なものには×印を解答欄に入力しなさい。<br><br>①「妻Ｂさんは、青色事業専従者として給与の支払を受けていますので、妻Ｂさんの合計所得金額の多寡にかかわらず、控除対象配偶者には該当せず、Ａさんは配偶者控除の適用を受けることはできません」<br>②「Ａさんは母Ｃさんに係る扶養控除の適用を受けることができます。母Ｃさんに係る扶養控除の額は58万円です」<br>③「契約者（＝保険料負担者）および死亡保険金受取人をＡさん、被保険者を妻Ｂさんとする定期保険（10年更新）に加入した場合、Ａさんが支払う保険料は、事業所得の金額の計算上、必要経費として認められます」",
        "choice1": "",
        "choice2": "",
        "choice3": "",
        "choice4": "",
        "choice5": "2022.09（改）",
        "categoryName": "タックス",
        "rontenName": "所得税の課税",
        "important": ""
    },
    {
        "id": 87,
        "shikenId": 4,
        "no": 12,
        "answerType": 4,
        "groupId": 0,
        "answer": "5350000,480000,242500",
        "score": "1,1,1",
        "question1": "<img src='/img/4_85.png'/>",
        "question2": "Ａさんの本年分の所得税の算出税額を計算した下記の表の空欄①～③に入る最も適切な数値を求めなさい。なお、問題の性質上、明らかにできない部分は「□□□」で示してある。<br><img src='/img/4_87_1.png'/>",
        "choice1": "円",
        "choice2": "円",
        "choice3": "円",
        "choice4": "",
        "choice5": "2022.09（改）",
        "categoryName": "タックス",
        "rontenName": "所得税の算出税額",
        "important": ""
    },
    {
        "id": 88,
        "shikenId": 4,
        "no": 13,
        "answerType": 5,
        "groupId": 0,
        "answer": "2,1,1",
        "score": "1,1,1",
        "question1": "<img src='/img/4_88.png'/>",
        "question2": "遺言に関する次の記述①～③について、適切なものには○印を、不適切なものには×印を解答欄に入力しなさい。<br><br>①「自筆証書遺言は、その遺言の全文および財産目録をパソコンで作成し、日付および氏名を自書して押印することで作成することができます」<br>②「自筆証書遺言は、所定の手続により、法務局（遺言書保管所）に保管することができます。法務局に保管された自筆証書遺言は、遺言者の相続開始時に、家庭裁判所の検認が不要となります」<br>③「公正証書遺言は、証人２人以上の立会いのもと、遺言者が遺言の趣旨を公証人に口授し、公証人がこれを筆記して作成しますが、推定相続人が証人になることはできません」",
        "choice1": "",
        "choice2": "",
        "choice3": "",
        "choice4": "",
        "choice5": "2023.09",
        "categoryName": "相続",
        "rontenName": "遺言に関する記述",
        "important": ""
    },
    {
        "id": 89,
        "shikenId": 4,
        "no": 14,
        "answerType": 6,
        "groupId": 0,
        "answer": "チ,ホ,ル,ロ",
        "score": "1,1,1,1",
        "question1": "<img src='/img/4_88.png'/>",
        "question2": "Ａさんの相続に関する以下の文章の空欄①～④に入る最も適切な語句を、下記の〈語句群〉のなかから選び、その記号を解答欄に入力しなさい。<br><br>Ⅰ　「遺言により、自宅および現預金等を妻Ｂさん、Ｘ社関連の資産を長男Ｃさんに相続させた場合、二男Ｄさんの遺留分を侵害するおそれがあります。仮に、遺留分を算定するための財産の価額が６億円の場合、二男Ｄさんの遺留分の金額は、（　①　）となります」<br>Ⅱ　「長男ＣさんがＸ社本社敷地を相続により取得し、当該敷地について、特定同族会社事業用宅地等として『小規模宅地等についての相続税の課税価格の計算の特例』の適用を受けた場合、当該敷地（相続税評価額8,000万円）について、相続税の課税価格に算入すべき価額を（　②　）とすることができます。なお、自宅敷地とＸ社本社敷地について、『小規模宅地等についての相続税の課税価格の計算の特例』の適用を受けようとする場合、（　③　）」<br>Ⅲ　「Ａさんが加入している一時払終身保険の死亡保険金は、みなし相続財産として相続税の課税対象となります。Ａさんの相続開始後、妻Ｂさんが受け取る死亡保険金2,000万円のうち、相続税の課税価格に算入される金額は、（　④　）となります」<br><img src='/img/4_89.png'/>",
        "choice1": "イ|ロ|ハ|ニ|ホ|ヘ|ト|チ|リ|ヌ|ル",
        "choice2": "",
        "choice3": "",
        "choice4": "",
        "choice5": "2023.09",
        "categoryName": "相続",
        "rontenName": "相続に関する語句",
        "important": ""
    },
    {
        "id": 90,
        "shikenId": 4,
        "no": 15,
        "answerType": 4,
        "groupId": 0,
        "answer": "4800,2820,13110",
        "score": "1,1,1",
        "question1": "<img src='/img/4_88.png'/>",
        "question2": "現時点（本年９月10日）において、Ａさんの相続が開始した場合における相続税の総額を試算した下記の表の空欄①～③に入る最も適切な数値を求めなさい。なお、相続税の課税価格の合計額は５億円とし、問題の性質上、明らかにできない部分は「□□□」で示してある。<br><img src='/img/4_90_1.png'/>",
        "choice1": "万円",
        "choice2": "万円",
        "choice3": "万円",
        "choice4": "",
        "choice5": "2023.09（改）",
        "categoryName": "相続",
        "rontenName": "相続税の総額",
        "important": ""
    },
    {
        "id": 91,
        "shikenId": 2,
        "no": 1,
        "answerType": 7,
        "groupId": 1,
        "answer": "1,2,2,1",
        "score": "1,1,1,1",
        "question1": "ファイナンシャル・プランナー（以下「ＦＰ」という）が、ファイナンシャル・プランニング業務を行ううえで関連業法等を順守することが重要である。ＦＰの行為に関する次の（ア）～（エ）の記述について、適切なものには○、不適切なものには×を入力しなさい。",
        "question2": "",
        "choice1": "社会保険労務士資格を有していないＦＰが、顧客が持参した「ねんきん定期便」を基に、有償で公的年金の受給見込み額を計算した。",
        "choice2": "弁護士資格を有していないＦＰが、報酬を得て顧客の離婚問題における交渉代理人となり、ＦＰ業務の一環として法律的な判断に基づいて相手方との交渉を代行した。",
        "choice3": "投資助言・代理業の登録を受けていないＦＰが、独自の景気見通しを基に、有償で具体的な投資時期等を判断し、助言を行った。",
        "choice4": "税理士資格を有していないＦＰが、相続対策を検討している顧客に対し、有料の相談業務におい て、仮定の事例に基づく一般的な解説を行った。",
        "choice5": "2022.09",
        "categoryName": "ライフ",
        "rontenName": "FPと関連業法",
        "important": ""
    },
    {
        "id": 92,
        "shikenId": 2,
        "no": 2,
        "answerType": 3,
        "groupId": 0,
        "answer": 3,
        "score": 2,
        "question1": "「消費者契約法」に関する次の記述のうち、最も不適切なものはどれか。",
        "question2": "",
        "choice1": "消費者が、商品を購入せずに退去したい旨を申し出たが、認められずに困惑して契約した場合で、購入場所が事業者の店舗であるときは、当該契約は取り消すことができる。",
        "choice2": "「販売した商品は、いかなる理由があっても、後から返品・キャンセルはできません」とした契約条項は無効である。",
        "choice3": "消費者契約法では、個人および法人を保護の対象としている。",
        "choice4": "消費者の努力義務として、契約に際して事業者から提供された情報を活用し、契約内容を理解することが求められている。",
        "choice5": "2022.09",
        "categoryName": "ライフ",
        "rontenName": "消費者契約法",
        "important": ""
    },
    {
        "id": 93,
        "shikenId": 2,
        "no": 3,
        "answerType": 3,
        "groupId": 0,
        "answer": 2,
        "score": 2,
        "question1": "下記＜証券口座の概要＞に関する次の記述のうち、最も適切なものはどれか。<br><img src='/img/2_93.png'/>",
        "question2": "",
        "choice1": "金融商品取引業者等は、（ａ）のみを選択している個人投資家に対して、その口座内での１年間の取引をまとめて取引報告書を交付しなければならない。",
        "choice2": "年初の売却で（ｂ）を選択した場合、同年中の２度目以降の売却の際に（ｃ）に変更できない。",
        "choice3": "（ｃ）を選択した場合、ほかの金融商品取引業者等に開設している特定口座における損益と通算することはできない。",
        "choice4": "（ｄ）の非課税投資枠を超えた取引は、（ａ）で取引しなければならない。",
        "choice5": "2023.09",
        "categoryName": "金融",
        "rontenName": "証券口座の種類",
        "important": ""
    },
    {
        "id": 94,
        "shikenId": 2,
        "no": 4,
        "answerType": 8,
        "groupId": 1,
        "answer": "1,10,6,2",
        "score": "1,1,1,1",
        "question1": "個人向け国債（変動１０年）に関する下表の空欄（ア）～（エ）にあてはまる適切な語句または数値を語群の中から選び、その番号のみを解答欄に入力しなさい。なお、同じ番号を何度選んでもよいこととする。<br><img src='/img/2_94.png'/>",
        "question2": "",
        "choice1": "1|2|3|4|5|6|7|8|9|10",
        "choice2": "",
        "choice3": "",
        "choice4": "",
        "choice5": "2023.09",
        "categoryName": "金融",
        "rontenName": "個人向け国債",
        "important": ""
    },
    {
        "id": 95,
        "shikenId": 2,
        "no": 5,
        "answerType": 3,
        "groupId": 0,
        "answer": 4,
        "score": 2,
        "question1": "馬場さんは、特定口座で保有しているＨＧ投資信託（追加型国内公募株式投資信託）の収益分配金を本年６月に受け取った。ＨＧ投資信託の運用状況が下記＜資料＞のとおりである場合、次の記述の空欄（ア）、（イ）にあてはまる語句の組み合わせとして、最も適切なものはどれか。 <br><img src='/img/2_95.png'/>",
        "question2": "",
        "choice1": "（ア）１３,６００円 （イ）される",
        "choice2": "（ア）１４,１００円 （イ）される",
        "choice3": "（ア）１３,６００円 （イ）されない",
        "choice4": "（ア）１４,１００円 （イ）されない",
        "choice5": "2023.09（改）",
        "categoryName": "金融",
        "rontenName": "収益分配金の課税関係",
        "important": ""
    },
    {
        "id": 96,
        "shikenId": 2,
        "no": 6,
        "answerType": 3,
        "groupId": 0,
        "answer": 1,
        "score": 2,
        "question1": "藤原さんは、勤務先に企業年金がないため、ＨＴ社を運営管理機関とする個人型確定拠出年金（以下「ｉＤｅＣｏ」という）に加入することを検討しており、下記＜資料＞を示してＦＰの小山さんに質問をした。小山さんの説明のうち、最も不適切なものはどれか。<br><img src='/img/2_96.png'/>",
        "question2": "",
        "choice1": "「ＨＴ社が扱う商品の中から１つだけを運用商品として、選択することはできません。」 ",
        "choice2": "「選択した運用商品は、ｉＤｅＣｏ加入中、原則として、いつでも変更することができます。」 ",
        "choice3": "「運用商品のうち投資信託には、国内株式型や国内債券型など投資対象となる資産によって分類されるものもありますが、バランス型のように複数資産を組み合わせたものもあります。」",
        "choice4": "「運用商品には、保険商品や定期預金等の元本確保型商品があり、所定の利息が上乗せされますが、金利情勢によっては利息額を手数料が上回る場合もあります。」",
        "choice5": "2023.09",
        "categoryName": "金融",
        "rontenName": "個人型確定拠出年金",
        "important": ""
    },
    {
        "id": 97,
        "shikenId": 2,
        "no": 7,
        "answerType": 3,
        "groupId": 0,
        "answer": 2,
        "score": 2,
        "question1": "建築基準法に従い、下記＜資料＞の土地に耐火建築物を建てる場合、建築面積の最高限度（ア）と延べ面積（床面積の合計）の最高限度（イ）の組み合わせとして、正しいものはどれか。なお、＜資料＞に記載のない条件は一切考慮しないこと。<br><img src='/img/2_97.png'/>",
        "question2": "",
        "choice1": "（ア）１６２㎡（イ）１０８０㎡",
        "choice2": "（ア）１８９㎡（イ） ８６４㎡",
        "choice3": "（ア）１６２㎡ （イ） ８６４㎡",
        "choice4": "（ア）１８９㎡ （イ）１０８０㎡",
        "choice5": "2022.09",
        "categoryName": "不動産",
        "rontenName": "建築面積と延べ面積の最高限度",
        "important": ""
    },
    {
        "id": 98,
        "shikenId": 2,
        "no": 8,
        "answerType": 8,
        "groupId": 1,
        "answer": "1,2,4,5",
        "score": "1,1,1,1",
        "question1": "羽田さんは、所有しているアパートを貸すに当たり、ＦＰの近藤さんに借家契約の説明を受けた。借地借家法に基づく借家契約に関する下表の空欄（ア）～（エ）に入る最も適切な語句を語群の中から選び、その番号のみを解答欄に入力しなさい。なお、同じ語句を何度選んでもよいこととする。<br><img src='/img/2_98.png'/>",
        "question2": "",
        "choice1": "1|2|3|4|5|6",
        "choice2": "",
        "choice3": "",
        "choice4": "",
        "choice5": "2022.09",
        "categoryName": "不動産",
        "rontenName": "借家契約",
        "important": ""
    },
    {
        "id": 99,
        "shikenId": 2,
        "no": 9,
        "answerType": 8,
        "groupId": 1,
        "answer": "4,2,7",
        "score": "1,1,1",
        "question1": "飯田さんは、１００㎡ほどの土地付き中古一戸建て住宅の購入を検討しており、ＦＰで税理士でもある浅見さんに不動産にかかる税金について質問をした。下記の空欄（ア）～（ウ）に入る適切な語句を語群の中から選び、その番号のみを解答欄に入力しなさい。<br><img src='/img/2_99.png'/>",
        "question2": "",
        "choice1": "1|2|3|4|5|6|7|8",
        "choice2": "",
        "choice3": "",
        "choice4": "",
        "choice5": "2022.09",
        "categoryName": "不動産",
        "rontenName": "不動産にかかる税金",
        "important": ""
    },
    {
        "id": 100,
        "shikenId": 2,
        "no": 10,
        "answerType": 3,
        "groupId": 0,
        "answer": 1,
        "score": 2,
        "question1": "下記＜資料＞は、天野さんが購入を検討している投資用マンションの概要である。この物件の表面利回り（年利）と実質利回り（年利）の組み合わせとして、正しいものはどれか。なお、＜資料＞に記載のない事項については一切考慮しないこととし、計算結果については小数点以下第３位を四捨五入すること。<br><img src='/img/2_100.png'/>",
        "question2": "",
        "choice1": "表面利回り（年利）：５.２０％ 実質利回り（年利）：３.８８％",
        "choice2": "表面利回り（年利）：５.２０％ 実質利回り（年利）：０.４０％",
        "choice3": "表面利回り（年利）：４.２０％ 実質利回り（年利）：３.８８％",
        "choice4": "表面利回り（年利）：４.２０％ 実質利回り（年利）：０.４０％",
        "choice5": "2022.09",
        "categoryName": "不動産",
        "rontenName": "表面利回りと実質利回り",
        "important": ""
    },
    {
        "id": 101,
        "shikenId": 2,
        "no": 11,
        "answerType": 9,
        "groupId": 1,
        "answer": "22,72,30",
        "score": "1,1,1",
        "question1": "井上隆也さん（３８歳）が加入の提案を受けた生命保険の保障内容は下記＜資料＞のとおりである。この生命保険に加入した場合、次の記述の空欄（ア）～（ウ）にあてはまる数値を解答欄に入力しなさい。なお、各々の記述はそれぞれ独立した問題であり、相互に影響を与えないものとする。<br><img src='/img/2_101.png'/><br><img src='/img/2_101_2.png'/>",
        "question2": "",
        "choice1": "万円",
        "choice2": "万円",
        "choice3": "万円",
        "choice4": "",
        "choice5": "2024.01",
        "categoryName": "リスク",
        "rontenName": "生命保険の補償内容",
        "important": ""
    },
    {
        "id": 102,
        "shikenId": 2,
        "no": 12,
        "answerType": 3,
        "groupId": 0,
        "answer": 1,
        "score": 2,
        "question1": "少額短期保険に関する次の記述の空欄（ア）～（エ）にあてはまる語句の組み合わせとして、最も適切なものはどれか。<br><img src='/img/2_102.png'/>",
        "question2": "",
        "choice1": "（ア）３００万円       （イ）１年       （ウ）２年       （エ）ならない",
        "choice2": "（ア）５００万円       （イ）１年       （ウ）１年       （エ）なる",
        "choice3": "（ア）３００万円       （イ）２年       （ウ）１年       （エ）ならない",
        "choice4": "（ア）５００万円       （イ）２年       （ウ）２年       （エ）なる",
        "choice5": "2024.01",
        "categoryName": "リスク",
        "rontenName": "少額短期保険",
        "important": ""
    },
    {
        "id": 103,
        "shikenId": 2,
        "no": 13,
        "answerType": 7,
        "groupId": 1,
        "answer": "2,1,1,2",
        "score": "1,1,1,1",
        "question1": "加瀬さん（４５歳）は、下記＜資料＞の自動車保険に加入している。下記＜資料＞に基づく次の（ア）～（エ）の記述のうち、適切なものには○、不適切なものには×を解答欄に入力しなさい。なお、＜資料＞に記載のない特約については考慮しないものとする。<br><img src='/img/2_103.png'/>",
        "question2": "",
        "choice1": "加瀬さんの友人（５０歳）が被保険自動車を運転中、他人にケガをさせ法律上の損害賠償責任を負った場合、補償の対象となる。",
        "choice2": "加瀬さんが被保険自動車を運転中、飛び石により窓ガラスが破損し、車両保険金のみが支払われた場合、当該事故はノンフリート等級別料率制度における「１等級ダウン事故」に該当する。",
        "choice3": "加瀬さんが被保険自動車を運転中、他人が運転する自動車と衝突し、加瀬さんがケガをした場合、過失割合にかかわらず治療費用の補償を受けることができる。",
        "choice4": "加瀬さんが所有する原動機付自転車を加瀬さんの妻（４０歳）が運転中、他人にケガをさせ法律上の損害賠償責任を負った場合、補償の対象とならない。",
        "choice5": "2024.01",
        "categoryName": "リスク",
        "rontenName": "自動車保険",
        "important": ""
    },
    {
        "id": 104,
        "shikenId": 2,
        "no": 14,
        "answerType": 3,
        "groupId": 0,
        "answer": 3,
        "score": 2,
        "question1": "羽田涼介さんが２０２３年中に支払った終身保険と終身医療保険の保険料は下記＜資料＞のとおりである。涼介さんの２０２３年分の所得税の計算における生命保険料控除額として、正しいものはどれか。なお、下記＜資料＞の保険について、これまでに契約内容の変更はないものとする。また、２０２３年分の生命保険料控除額が最も多くなるように計算すること。<br><img src='/img/2_104.png'/>",
        "question2": "",
        "choice1": "７８,７８０円",
        "choice2": "８３,７８０円",
        "choice3": "８８,７８０円",
        "choice4": "９３,７８０円",
        "choice5": "2024.01",
        "categoryName": "リスク",
        "rontenName": "生命保険料控除額",
        "important": ""
    },
    {
        "id": 105,
        "shikenId": 2,
        "no": 15,
        "answerType": 9,
        "groupId": 0,
        "answer": 540,
        "score": 2,
        "question1": "会社員の小田さんは、本年１２月末で３５年４ヵ月勤め続けてきた株式会社ＹＺを退職し、退職一時金３,０００万円を受け取った。この退職一時金に係る退職所得の金額はいくらになるか。なお、小田さんは、勤務先の役員であったことはなく、退職は障害者になったことに基因するものではない。また、解答に当たっては、解答欄に記載されている単位に従うこと。",
        "question2": "",
        "choice1": "万円",
        "choice2": "",
        "choice3": "",
        "choice4": "",
        "choice5": "2023.01（改）",
        "categoryName": "タックス",
        "rontenName": "退職所得の金額",
        "important": ""
    },
    {
        "id": 106,
        "shikenId": 2,
        "no": 16,
        "answerType": 3,
        "groupId": 0,
        "answer": 2,
        "score": 2,
        "question1": "公的年金等に係る所得税の取扱いに関する次の記述のうち、最も不適切なものはどれか。",
        "question2": "",
        "choice1": "小規模企業共済の共済金や確定拠出年金の老齢給付金は、年金形式で受け取る場合、公的年金等に係る雑所得の収入金額となる。",
        "choice2": "公的年金等に係る雑所得の金額の計算は、「公的年金等の収入金額－公的年金等控除額」により計算するが、公的年金等控除額は、受給者の年齢が７０歳以上か７０歳未満かにより、控除額が異なる。",
        "choice3": "公的年金等以外の総合課税となる雑所得の金額に、赤字が生じた場合、その赤字の金額と公的年金等に係る雑所得の金額を通算し、雑所得の金額を計算することができる。",
        "choice4": "公的年金等の収入金額が４００万円以下であり、かつ、その公的年金等の全部が源泉徴収の対象となる場合において、公的年金等に係る雑所得以外の所得金額の合計が２０万円以下であるときは、確定申告は不要である。",
        "choice5": "2023.01",
        "categoryName": "タックス",
        "rontenName": "公的年金等に係る所得税の取り扱い",
        "important": ""
    },
    {
        "id": 107,
        "shikenId": 2,
        "no": 17,
        "answerType": 9,
        "groupId": 1,
        "answer": "55,65,10",
        "score": "1,1,1",
        "question1": "所得税の青色申告特別控除制度に関する次の記述の空欄（ア）～（ウ）に入る適切な数値を解答欄に入力しなさい。<br><img src='/img/2_107.png'/>",
        "question2": "",
        "choice1": "万円",
        "choice2": "万円",
        "choice3": "万円",
        "choice4": "",
        "choice5": "2023.01",
        "categoryName": "タックス",
        "rontenName": "青色申告特別控除制度",
        "important": ""
    },
    {
        "id": 108,
        "shikenId": 2,
        "no": 18,
        "answerType": 3,
        "groupId": 0,
        "answer": 4,
        "score": 2,
        "question1": "会社員の山岸さんの本年分の所得等が下記＜資料＞のとおりである場合、山岸さんが本年分の所得税の確定申告を行う際に、給与所得と損益通算できる損失に関する次の記述のうち、最も適切なものはどれか。なお、▲が付された所得金額は、その所得に損失が発生していることを意味する。<br><img src='/img/2_108.png'/>",
        "question2": "",
        "choice1": "不動産所得▲１００万円と損益通算できる。",
        "choice2": "副業の雑所得▲１０万円と損益通算できる。",
        "choice3": "上場株式の譲渡所得▲１５０万円と損益通算できる。",
        "choice4": "損益通算できる損失はない。",
        "choice5": "2023.01（改）",
        "categoryName": "タックス",
        "rontenName": "損益通算できる損失",
        "important": ""
    },
    {
        "id": 109,
        "shikenId": 2,
        "no": 19,
        "answerType": 9,
        "groupId": 0,
        "answer": 3200,
        "score": 2,
        "question1": "下記の相続事例（本年４月１５日相続開始）における相続税の課税価格の合計額を計算しなさい。なお、記載のない条件については一切考慮しないこととする。また、解答に当たっては、解答欄に記載されている単位に従うこと。<br><img src='/img/2_109.png'/>",
        "question2": "",
        "choice1": "万円",
        "choice2": "",
        "choice3": "",
        "choice4": "",
        "choice5": "2022.05（改）",
        "categoryName": "相続",
        "rontenName": "相続税の課税価格の合計額",
        "important": ""
    },
    {
        "id": 110,
        "shikenId": 2,
        "no": 20,
        "answerType": 8,
        "groupId": 1,
        "answer": "4,1,7",
        "score": "1,1,1",
        "question1": "下記＜親族関係図＞の場合において、民法の規定に基づく法定相続分および遺留分に関する次の記述の空欄（ア）～（ウ）に入る適切な語句または数値を語群の中から選び、解答欄に入力しなさい。なお、同じ語句または数値を何度選んでもよいこととする。<br><img src='/img/2_110.png'/>",
        "question2": "",
        "choice1": "1|2|3|4|5|6|7|8|9",
        "choice2": "",
        "choice3": "",
        "choice4": "",
        "choice5": "2022.05（改）",
        "categoryName": "相続",
        "rontenName": "法定相続分および遺留分",
        "important": ""
    },
    {
        "id": 111,
        "shikenId": 2,
        "no": 21,
        "answerType": 3,
        "groupId": 0,
        "answer": 2,
        "score": 2,
        "question1": "相続税における「小規模宅地等の評価減の特例」に関する下表の空欄（ア）～（ウ）にあてはまる数値の組み合わせとして、正しいものはどれか。<br><img src='/img/2_111.png'/>",
        "question2": "",
        "choice1": "（ア）３３０ （イ）２４０ （ウ）７０",
        "choice2": "（ア）３３０ （イ）２００ （ウ）８０",
        "choice3": "（ア）３００ （イ）２４０ （ウ）７０",
        "choice4": "（ア）３００ （イ）２００ （ウ）８０",
        "choice5": "2022.05",
        "categoryName": "相続",
        "rontenName": "小規模宅地等の評価減の特例",
        "important": ""
    },
    {
        "id": 112,
        "shikenId": 2,
        "no": 22,
        "answerType": 3,
        "groupId": 0,
        "answer": 4,
        "score": 2,
        "question1": "下記＜資料＞の宅地（貸家建付地）に係る路線価方式による相続税評価額の計算式として、正しいものはどれか。<br><img src='/img/2_112.png'/>",
        "question2": "",
        "choice1": "２５０,０００円×１.００×４００㎡",
        "choice2": "２５０,０００円×１.００×４００㎡ ×６０％",
        "choice3": "２５０,０００円×１.００×４００㎡ ×（１－６０％）",
        "choice4": "２５０,０００円×１.００×４００㎡ ×（１－６０％×３０％×１００％）",
        "choice5": "2022.05",
        "categoryName": "相続",
        "rontenName": "路線価方式による相続税評価額",
        "important": ""
    },
    {
        "id": 113,
        "shikenId": 2,
        "no": 23,
        "answerType": 9,
        "groupId": 0,
        "answer": 518,
        "score": 2,
        "question1": "福岡家のキャッシュフロー表の空欄（ア）は洋司さんの可処分所得である。下表のデータに基づいて、空欄（ア）にあてはまる数値を計算しなさい。なお、本年における洋司さんの収入は給与収入のみである。<br><img src='/img/2_113_2.png'/><br><img src='/img/2_113.png'/><br>",
        "question2": "",
        "choice1": "万円",
        "choice2": "",
        "choice3": "",
        "choice4": "",
        "choice5": "2023.09（改）",
        "categoryName": "ライフ",
        "rontenName": "CF表",
        "important": ""
    },
    {
        "id": 114,
        "shikenId": 2,
        "no": 24,
        "answerType": 9,
        "groupId": 0,
        "answer": 396,
        "score": 2,
        "question1": "福岡家のキャッシュフロー表の空欄（イ）にあてはまる数値を計算しなさい。なお、計算に当たっては、キャッシュフロー表中に記載の整数を使用し、計算結果については万円未満を四捨五入すること。<br><img src='/img/2_113.png'/>",
        "question2": "",
        "choice1": "万円",
        "choice2": "",
        "choice3": "",
        "choice4": "",
        "choice5": "2023.09",
        "categoryName": "ライフ",
        "rontenName": "CF表",
        "important": ""
    },
    {
        "id": 115,
        "shikenId": 2,
        "no": 25,
        "answerType": 9,
        "groupId": 0,
        "answer": 1136,
        "score": 2,
        "question1": "福岡家のキャッシュフロー表の空欄（ウ）にあてはまる数値を計算しなさい。なお、計算に当たっては、キャッシュフロー表中に記載の整数を使用し、計算結果については万円未満を四捨五入すること。<br><img src='/img/2_113.png'/>",
        "question2": "",
        "choice1": "万円",
        "choice2": "",
        "choice3": "",
        "choice4": "",
        "choice5": "2023.09",
        "categoryName": "ライフ",
        "rontenName": "CF表",
        "important": ""
    },
    {
        "id": 116,
        "shikenId": 2,
        "no": 26,
        "answerType": 9,
        "groupId": 0,
        "answer": 4525000,
        "score": 2,
        "question1": "<img src='/img/2_116.png'/><br>皆川さんは、自宅のリフォーム費用として、１０年後に５００万円を準備したいと考えている。年利１.０％で１０年間複利運用する場合、現在いくらの資金があればよいか。",
        "question2": "",
        "choice1": "円",
        "choice2": "",
        "choice3": "",
        "choice4": "",
        "choice5": "2023.05",
        "categoryName": "ライフ",
        "rontenName": "6つの係数",
        "important": ""
    },
    {
        "id": 117,
        "shikenId": 2,
        "no": 27,
        "answerType": 9,
        "groupId": 0,
        "answer": 975000,
        "score": 2,
        "question1": "<img src='/img/2_116.png'/><br>山根さんは、退職金の２,５００万円を今後３０年間、年利１.０％で複利運用しながら毎年１回、年末に均等に生活資金として取り崩していきたいと考えている。毎年取り崩すことができる最大金額はいくらになるか。",
        "question2": "",
        "choice1": "円",
        "choice2": "",
        "choice3": "",
        "choice4": "",
        "choice5": "2023.05",
        "categoryName": "ライフ",
        "rontenName": "6つの係数",
        "important": ""
    },
    {
        "id": 118,
        "shikenId": 2,
        "no": 28,
        "answerType": 9,
        "groupId": 0,
        "answer": 930000,
        "score": 2,
        "question1": "<img src='/img/2_116.png'/><br>安藤さんは、子どもの留学資金として、１５年後に１,５００万円を準備したいと考えている。年利１.０％で複利運用しながら毎年年末に一定額を積み立てる場合、毎年いくらずつ積み立てればよいか。",
        "question2": "",
        "choice1": "円",
        "choice2": "",
        "choice3": "",
        "choice4": "",
        "choice5": "2023.05",
        "categoryName": "ライフ",
        "rontenName": "6つの係数",
        "important": ""
    },
    {
        "id": 119,
        "shikenId": 2,
        "no": 29,
        "answerType": 3,
        "groupId": 0,
        "answer": 2,
        "score": 2,
        "question1": "<img src='/img/2_119.png'/><br><br>和雄さんは、現在居住している自宅の住宅ローンの繰上げ返済を検討しており、ＦＰの岡さんに質問をした。和雄さんが住宅ローンを１２０回返済後に、１００万円以内で期間短縮型の繰上げ返済をする場合、この繰上げ返済により短縮される返済期間として、正しいものはどれか。なお、計算に当たっては、下記＜資料＞を使用し、繰上げ返済額は１００万円を超えない範囲での最大額とすること。また、繰上げ返済に伴う手数料等は考慮しないものとする。<br><img src='/img/2_119_2.png'/>",
        "question2": "",
        "choice1": "１年８ヵ月",
        "choice2": "１年７ヵ月",
        "choice3": "１年６ヵ月",
        "choice4": "１０ヵ月",
        "choice5": "2023.01",
        "categoryName": "ライフ",
        "rontenName": "繰上げ返済により短縮される返済期間",
        "important": ""
    },
    {
        "id": 120,
        "shikenId": 2,
        "no": 30,
        "answerType": 3,
        "groupId": 0,
        "answer": 2,
        "score": 2,
        "question1": "<img src='/img/2_119.png'/><br><br>和雄さんは、翔太さんの高校の授業料負担についてＦＰの岡さんに質問をした。「高等学校等就学支援金制度」に係る下記＜資料＞に関する岡さんの説明のうち、最も不適切なものはどれか。<br><img src='/img/2_120.png'/>",
        "question2": "",
        "choice1": "「所得判定基準が３０４,２００円未満の場合、国公立高校の授業料負担は実質０円になります。」",
        "choice2": "「高校入学時に高等学校等就学支援金の受給資格に該当しない場合、その後在学中に申請はできません。」",
        "choice3": "「高等学校等就学支援金は、学校設置者が生徒本人に代わって受け取り授業料に充てるしくみのため、生徒や保護者が直接お金を受け取るものではありません。」",
        "choice4": "「高等学校等就学支援金制度を利用するためには申請が必要で、原則として、保護者等の収入状況を登録する必要があります。」",
        "choice5": "2023.01 ",
        "categoryName": "ライフ",
        "rontenName": "高等学校等就学支援金制度",
        "important": ""
    },
    {
        "id": 121,
        "shikenId": 2,
        "no": 31,
        "answerType": 7,
        "groupId": 1,
        "answer": "1,1,2,2",
        "score": "1,1,1,1",
        "question1": "<img src='/img/2_119.png'/><br>大久保家が契約している保険の保険金等が支払われた場合の課税に関する次の（ア）～（エ）の記述について、適切なものには○、不適切なものには×を解答欄に入力しなさい。",
        "question2": "",
        "choice1": "和雄さんが余命６ヵ月以内と診断され、定期保険Ａからリビング・ニーズ特約の生前給付金を受け取った後、和雄さんが死亡した場合、相続開始時点における残額は、相続税の課税対象となる。",
        "choice2": "和雄さんが死亡したことにより、留美子さんが受け取る定期保険Ａの死亡保険金は、相続税の課税対象となる。",
        "choice3": "自宅が火災で全焼となり、和雄さんが受け取る火災保険Ｂの損害保険金は、所得税（一時所得）の課税対象となる。",
        "choice4": "留美子さんが、がんに罹患して陽子線治療を受けたことによって、留美子さんが受け取る医療保険Ｃからの先進医療給付金は、所得税（一時所得）の課税対象となる。",
        "choice5": "2023.01 ",
        "categoryName": "タックス",
        "rontenName": "保険金等に対する課税",
        "important": ""
    },
    {
        "id": 122,
        "shikenId": 2,
        "no": 32,
        "answerType": 8,
        "groupId": 1,
        "answer": "2,3,7",
        "score": "1,1,1",
        "question1": "和雄さんは、現在勤めている会社を自己都合退職した場合に受給できる雇用保険の基本手当についてＦＰの岡さんに質問をした。雇用保険の基本手当に関する次の記述の空欄（ア）～（ウ）にあてはまる適切な語句を語群の中から選び、その番号のみを解答欄に入力しなさい。なお、和雄さんは本年１月に自己都合退職するものと仮定し、現在の会社に２２歳から勤務し、継続して雇用保険に加入しており、雇用保険の基本手当の受給要件はすべて満たしているものとする。また、和雄さんには、この他に雇用保険の加入期間はなく、障害者等の就職困難者には該当しないものとし、延長給付については考慮しないものとする。<br><img src='/img/2_122.png'/>",
        "question2": "",
        "choice1": "1|2|3|4|5|6|7|8",
        "choice2": "",
        "choice3": "",
        "choice4": "",
        "choice5": "2023.01 （改）",
        "categoryName": "ライフ",
        "rontenName": "雇用保険の基本手当",
        "important": ""
    },
    {
        "id": 123,
        "shikenId": 2,
        "no": 33,
        "answerType": 7,
        "groupId": 1,
        "answer": "2,1,1,1",
        "score": "1,1,1,1",
        "question1": "和雄さんの妹の枝里子さんは、民間企業に勤務する会社員であり、現在妊娠中である。和雄さんは、枝里子さんが出産のために仕事を休んだ場合に支給される出産手当金や、産前産後休業中の社会保険料の取扱いについて、ＦＰの岡さんに質問をした。出産手当金および産前産後休業中の社会保険料に関する次の（ア）～（エ）の記述について、適切なものには○、不適切なものには×を解答欄に入力しなさい。なお、枝里子さんは、全国健康保険協会管掌健康保険（協会けんぽ）の被保険者であり、かつ厚生年金の被保険者であるものとする。<br><img src='/img/2_123.png'/>",
        "question2": "",
        "choice1": "空欄（ ａ ）にあてはまる語句は「４２日」である。",
        "choice2": "空欄（ ｂ ）にあてはまる語句は「３分の２」である。",
        "choice3": "空欄（ ｃ ）にあてはまる語句は「本人負担分および事業主負担分」である。",
        "choice4": "空欄（ ｄ ）にあてはまる語句は「保険料を納めた期間」である。",
        "choice5": "2023.01",
        "categoryName": "ライフ",
        "rontenName": "産前産後期間の社会保険",
        "important": ""
    },
    {
        "id": 124,
        "shikenId": 2,
        "no": 34,
        "answerType": 7,
        "groupId": 1,
        "answer": "2,2,1,2",
        "score": "1,1,1,1",
        "question1": "和雄さんは、労働者災害補償保険（以下「労災保険」という）について、ＦＰの岡さんに質問をした。労災保険の概要に関する次の（ア）～（エ）の記述について、適切なものには○、不適切なものには×を解答欄に入力しなさい。",
        "question2": "",
        "choice1": "労災保険は、在宅勤務をする労働者を給付対象としない。",
        "choice2": "労災保険における保険料率は、業種にかかわらず一律である。",
        "choice3": "労災保険の保険料は、その全額を事業主が負担する。",
        "choice4": "労働者が業務上の災害により労災指定病院等において療養を受けた場合は、その費用の１割を労働者が負担し、残る部分が療養補償給付となる。",
        "choice5": "2023.01",
        "categoryName": "ライフ",
        "rontenName": "労働者災害補償保険",
        "important": ""
    },
    {
        "id": 125,
        "shikenId": 2,
        "no": 35,
        "answerType": 9,
        "groupId": 1,
        "answer": 14020,
        "score": 2,
        "question1": "<img src='/img/2_124_1.png'/><br><img src='/img/2_124_2.png'/><br><br>ＦＰの飯田さんは、まず本年４月１日現在における安藤家（雅之さんと裕子さん）のバランスシート分析を行うこととした。下表の空欄（ア）にあてはまる数値を計算しなさい。<br><img src='/img/2_125.png'/>",
        "question2": "",
        "choice1": "万円",
        "choice2": "",
        "choice3": "",
        "choice4": "",
        "choice5": "2024.05（改）",
        "categoryName": "ライフ",
        "rontenName": "バランスシート",
        "important": ""
    },
    {
        "id": 126,
        "shikenId": 2,
        "no": 36,
        "answerType": 8,
        "groupId": 1,
        "answer": "2,4,7",
        "score": "1,1,1",
        "question1": "<img src='/img/2_124_1.png'/><br><img src='/img/2_124_2.png'/><br><br>下記＜資料＞は、雅之さんの前年分の「給与所得の源泉徴収票（一部省略）」であり、雅之さんは、所得控除の額について、ＦＰで税理士でもある飯田さんに質問をした。下記＜資料＞に基づく雅之さんの前年分の所得控除の額に関する次の記述の空欄（ア）～（ウ）にあてはまる適切な数値を語群の中から選び、その番号のみを解答欄に入力しなさい。なお、雅之さんには、前年中において給与所得以外に申告すべき所得はなく、年末調整の対象となった所得控除以外に適用を受けることのできる所得控除はない。また、記載のない事項については一切考慮しないものとする。<br><img src='/img/2_126.png'/>",
        "question2": "",
        "choice1": "1|2|3|4|5|6|7|8",
        "choice2": "",
        "choice3": "",
        "choice4": "",
        "choice5": "2024.05（改）",
        "categoryName": "タックス",
        "rontenName": "源泉徴収票の見方",
        "important": ""
    },
    {
        "id": 127,
        "shikenId": 2,
        "no": 37,
        "answerType": 3,
        "groupId": 0,
        "answer": 1,
        "score": 2,
        "question1": "<img src='/img/2_124_1.png'/><br><img src='/img/2_124_2.png'/><br><br>雅之さんの父の三郎さんは老人ホームへの入居を検討しているため、ＦＰで税理士でもある飯田さんに所有する自宅の売却について相談をした。下記＜資料＞に基づく三郎さんの自宅の売却に係る所得税および住民税に関する次の記述の空欄（ア）～（ウ）にあてはまる語句の組み合わせとして、最も適切なものはどれか。なお、「居住用財産を譲渡した場合の３,０００万円の特別控除」および「居住用財産を譲渡した場合の長期譲渡所得の軽減税率の特例」の適用要件は満たしており、これらの適用を受けるものとする。また、所得控除その他記載のない事項については一切考慮しないものとする。<br><img src='/img/2_127.png'/>",
        "question2": "",
        "choice1": "（ア）２００万円 （イ）１０％ （ウ）４％",
        "choice2": "（ア）２００万円 （イ）１５％ （ウ）５％",
        "choice3": "（ア）６００万円 （イ）１０％ （ウ）５％",
        "choice4": "（ア）６００万円 （イ）１５％ （ウ）４％",
        "choice5": "2024.05 ",
        "categoryName": "不動産",
        "rontenName": "譲渡所得の特例",
        "important": ""
    },
    {
        "id": 128,
        "shikenId": 2,
        "no": 38,
        "answerType": 9,
        "groupId": 1,
        "answer": 1494948,
        "score": 2,
        "question1": "<img src='/img/2_124_1.png'/><br><img src='/img/2_124_2.png'/><br><br>雅之さんが取引をしている国内の証券会社から送付された２０２３年分の特定口座年間取引報告書（抜粋）が下記＜資料＞のとおりである場合、２０２４年に繰り越すことのできる上場株式等の譲渡損失の金額（上限）を計算しなさい。なお、雅之さんはこの他に有価証券取引は行っておらず、２０２２年以前から繰り越された上場株式等の譲渡損失はないものとする。また、解答に当たっては、解答用欄に記載されている単位に従うこと。<br><img src='/img/2_128.png'/>",
        "question2": "",
        "choice1": "円",
        "choice2": "",
        "choice3": "",
        "choice4": "",
        "choice5": "2024.05",
        "categoryName": "金融",
        "rontenName": "特定口座年間取引報告書",
        "important": ""
    },
    {
        "id": 129,
        "shikenId": 2,
        "no": 39,
        "answerType": 3,
        "groupId": 0,
        "answer": 4,
        "score": 2,
        "question1": "<img src='/img/2_124_1.png'/><br><img src='/img/2_124_2.png'/><br><br>裕子さんは、老齢年金の繰上げ受給を検討しており、ＦＰの飯田さんに質問をした。裕子さんの老齢基礎年金の繰上げ受給に関する次の記述のうち、最も不適切なものはどれか。",
        "question2": "",
        "choice1": "「老齢基礎年金の繰上げ請求を行った場合の年金額は、０.４％に繰上げ請求月から５歳に達する月の前月までの月数を乗じた率に基づき減額されます。」",
        "choice2": "「老齢厚生年金の繰上げ請求を行うことができる人が老齢基礎年金の繰上げ請求をする場合、老齢厚生年金も同時に繰上げ請求しなければなりません。」",
        "choice3": "「老齢基礎年金と併せて付加年金を受給できる人が老齢基礎年金の繰上げ請求をした場合、付加年金も減額されて繰上げ支給されます。」",
        "choice4": "「２０歳から６０歳になるまでの間に保険料を納めていなかった期間がある人については、老齢基礎年金の繰上げ請求をした後であっても、国民年金に任意加入することができます。」",
        "choice5": "2024.05 ",
        "categoryName": "ライフ",
        "rontenName": "老齢年金の繰上げ受給",
        "important": ""
    },
    {
        "id": 130,
        "shikenId": 2,
        "no": 40,
        "answerType": 3,
        "groupId": 0,
        "answer": 3,
        "score": 2,
        "question1": "<img src='/img/2_124_1.png'/><br><img src='/img/2_124_2.png'/><br><br>雅之さんの妹の洋子さんは、本年２月中に病気（私傷病）療養のため休業した日がある。ＦＰの飯田さんが下記＜資料＞に基づいて計算した洋子さんに支給される傷病手当金の額として、正しいものはどれか。なお、洋子さんは全国健康保険協会管掌健康保険（協会けんぽ）の被保険者であるものとする。また、記載のない事項については一切考慮しないものとする。<br><img src='/img/2_130.png'/>",
        "question2": "",
        "choice1": "１７,５６０円",
        "choice2": "２６,３４０円",
        "choice3": "４３,９００円",
        "choice4": "７０,２４０円",
        "choice5": "2024.05（改）",
        "categoryName": "ライフ",
        "rontenName": "傷病手当金",
        "important": ""
    }
]