<template>
  <div class="header">
    <div>
      <div class="d-flex  align-items-center" style="height:55px">
        <div class="flex-fill">
          <button class="titleBtn btn btn-link" @click="tapLogo">トリセツシリーズ 購入者特典！{{ shikenName }}</button>
        </div>
        
      </div>

      
      <div class="text-center" style="font-size: 1.1em; position: relative">
        <div style="position: absolute; right:16px">
          <Timer @end="endForce" />
      </div>
      {{ $store.state.data.length}}問中　{{ $store.state.index + 1 }}問目</div>
    </div>
    <hr class="m-0" />
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue, Emit } from "vue-property-decorator";

import StorageService from "../services/storage";
import BasicUtil from "../services/basicUtil";
import Timer from "./Timer.vue";
import Question from "@/models/question";
import HttpClient from "@/services/httpClient";

@Component({
  components: { Timer }
})
export default class HeaderExam extends Vue {
  private shikenId: number = 0
  private shikenName: string = ""
  created() {
    const datas:Question[] = this.$store.state.data
    if (datas.length == 0) return
    this.shikenId = datas[0].shikenId
    const shiken = new HttpClient().getShiken(this.shikenId)
    if (shiken == null) return
    this.shikenName = shiken.name
  }
  mounted() {

  }

  tapLogo() {
    new BasicUtil().reload();
  }

  

  private endForce() {
    alert("制限時間が終了しました")
    this.$router.push(`/end`);
  }
}
</script>
<style scoped>
.header {
  height: 85px;
}
.header {
  height: 85px;
  background-color: #eee;
  text-align: center;
}
.header>div {
  height: 84px;
}

.header .titleBtn {
  text-decoration: none;
  color: #212529;
  font-size: 1.2em;
}

.end-button {
  width: 120px;
}
</style>